import * as Redux from 'redux';
import { combineReducers } from 'redux';
import * as ReduxDevtoolsExtension from 'redux-devtools-extension';
import * as ReduxLogger from 'redux-logger';
import thunkMiddleware from 'redux-thunk';
import sharedReducer from "./reducers/shared";
import cartReducer from "./reducers/cart";
import checkoutReducer from "./reducers/checkout";
import sessionReducer from "./reducers/session";
import notificationsReducer from "./reducers/notifications";
import catalogReducer from "./reducers/catalog";
import translationReducer from './reducers/translation';
import trackingReducer from './reducers/tracking';

const rootReducer = combineReducers({
  shared: sharedReducer,
  notifications: notificationsReducer,
  cart: cartReducer,
  session: sessionReducer,
  checkout: checkoutReducer,
  catalog: catalogReducer,
  translation: translationReducer,
  tracking: trackingReducer
});

const loggerMiddleware = ReduxLogger.createLogger({
  collapsed: true,
  duration: true,
  logErrors: true,
  diff: true,
});

const store = process.env.NODE_ENV === 'production' ?
  Redux.createStore(rootReducer, ReduxDevtoolsExtension.composeWithDevTools(Redux.applyMiddleware(thunkMiddleware))) :
  Redux.createStore(rootReducer, ReduxDevtoolsExtension.composeWithDevTools(Redux.applyMiddleware(thunkMiddleware, loggerMiddleware)));

export default store