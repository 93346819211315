import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from './react-store/store';
import RjsFrontendApp from "./react-app/RjsFrontendApp";

export const InitFarmyApp = (targetElementId) => {
  if (window.Rails.env == 'development') {
    ReactDOM.render(
      <React.StrictMode>
        <Provider store={store}>
          <RjsFrontendApp />
        </Provider>
      </React.StrictMode>,
      document.getElementById(targetElementId)
    );
  } else {
    ReactDOM.render(
      <Provider store={store}>
        <RjsFrontendApp />
      </Provider>,
      document.getElementById(targetElementId)
    );
  }
}

window.InitFarmyApp = InitFarmyApp;