import React, {useEffect, lazy, Suspense} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {setLayout, setWindowWidth} from "../../react-store/reducers/shared";
import currentTheme from "../../react-themes/theme";
import LoadingStub from "../../react-components/shared/LoadingStub";

const LayoutResolver = (props) => {
  const dispatch = useDispatch();
  const layout = useSelector(state => state.shared.layout);
  const windowWidth = useSelector(state => state.shared.windowWidth);
  const theme = currentTheme();
  let lastWidth, eventTimeOut;

  const maxMobileWindowWidth = theme.dimensions.mobile.maxWindowWidth;

  const layoutSetter = () => {
    let width = document.body.clientWidth;

    if (width === lastWidth) {
      let layoutType = width <= maxMobileWindowWidth ? 'mobile' : 'desktop';

      if (layoutType !== layout) dispatch(setLayout(layoutType));
      if (width !== windowWidth) dispatch(setWindowWidth(width))
    } else {
      lastWidth = width;
      layoutSetterTimeout()
    }
  }

  const layoutSetterTimeout = () => {
    if (eventTimeOut)
      clearTimeout(eventTimeOut);

    eventTimeOut = setTimeout(() => {
      layoutSetter()
    }, 300)
  }

  useEffect(() => {
    window.addEventListener('resize', layoutSetterTimeout);
    return () => {
      window.removeEventListener('resize', layoutSetterTimeout)
    }
  }, [])

  const MainLayout = lazy(() => import(`./${layout === 'mobile' ? 'MobileLayout' : 'DesktopFluidLayout'}`));

  return (
    <Suspense fallback={<LoadingStub/>}><MainLayout {...props}/></Suspense>
  )
};

export default LayoutResolver;
