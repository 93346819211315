/**
 * Fake AngularJS root scope that poses as $rootScope for legacy angular components
 * and implements the AngularIntegration event model.
 *
 * @type {{$on: ngRootScope.$on, listeners: {}, $broadcast: (function(*, *=): boolean)}}
 */
export const ngRootScope = {
  listeners: {},

  $broadcast: (eventName, payload) => {
    if (window.Rails && window.Rails.env == 'development') console.info("$ngRootScope:", eventName, payload);

    if (ngRootScope.listeners[eventName] == null)
      ngRootScope.listeners[eventName] = [];

    if (ngRootScope.listeners[eventName] == null)
      return false;

    ngRootScope.listeners[eventName].map(listener => {
      listener.apply(null, payload);
    })

    return true;
  },

  $on: (eventName, callback) => {
    if (ngRootScope.listeners[eventName] == null)
      ngRootScope.listeners[eventName] = [];

    ngRootScope.listeners[eventName].push(callback);

    return () => {
      ngRootScope.listeners[eventName] = ngRootScope.listeners[eventName].filter(c => c != callback);
    }
  }
};

const AngularIntegration = {
  $location: null,

  $broadcast: function(eventName, parameter) {
    return new Promise((resolve, reject) => {
      ngRootScope.$broadcast(eventName, parameter);

      resolve();
    });

  },

  $on: function(eventName, callback) {
    return new Promise((resolve, reject) => {
      let unsubscribe = ngRootScope.$on(eventName, callback);
      setTimeout(function() {
        resolve(unsubscribe)
      }, 0)
    })
  },

  $: function(readyCallback) {
    readyCallback();
  },

  $store: function(key, value) {
    window.localStorage[key] = value;
  },

  $fetch: function(key) {
    return window.localStorage[key];
  },
};

window.AngularIntegration = AngularIntegration;
window.$ngRootScope = ngRootScope;

window.$ti = (function(key, opts) {
  if (opts) {
    return window.$translate.instant(key, opts)
  } else return window.$translate.instant(key, opts);
});