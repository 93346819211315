/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

function importAll(r) {
  r.keys().forEach(r);
}


// import * as _ from 'underscore';
// import * as $ from 'jquery';
// import * as sprintf from 'sprintf';
require('../ng-frontend/vendor/moment');
require('../ng-frontend/vendor/moment-de');

// require("babel-core/register");
// require("babel-polyfill");

// import * as lzstring from 'lz-string';
// window.lzstring = lzstring;

// import 'angular15farmy';
// import 'angular-sanitize15';
// import 'angular-cookies15';
// import 'angular-translate';
// import 'angular-translate-loader-url';
// import 'angular-block-ui';
//
// import 'ng-token-auth'
// import 'angular-cookie'

require('../ng-frontend/angular-integration');
// importAll(require.context('../ng-frontend/modules', true, /\.js$/));
// importAll(require.context('../ng-frontend/utils', true, /\.js$/));
//
// require('../ng-frontend/ng-frontend-init');
// importAll(require.context('../ng-frontend/config', true, /\.js$/));

// require('../ng-frontend/services/alerts');
// require('../ng-frontend/services/pusher-service');
// require('../ng-frontend/services/partner-pixel-helper');
// require('../ng-frontend/services/tracking');
// require('../ng-frontend/services/notifications');
// require('../ng-frontend/services/user-notifications');
// require('../ng-frontend/services/google-data-layer-adapter');

require('./../ng-frontend/react-app-init');