import {createSlice} from '@reduxjs/toolkit';
import CartData from '../../react-services/cart-data-rx';
import UserService from '../../react-services/user-service-rx';
import axios from 'axios';
import Alerts from '../../react-services/alerts-rx';
import {useLocation} from 'react-router-dom';
import {setAddressHandled, setShipAddress} from './checkout';
import {hasValidPhone, isBlankAddress, isSameAddress, isValidAddress} from '../helpers/AddressHelper';
import store from '../store';

export const safeSerializeLocalStorage = (key) => {
  try {
    if (window.localStorage['Farmy_' + key] == null)
      return null;

    const existingValue = JSON.parse(window.localStorage['Farmy_' + key]);

    return existingValue;
  } catch(e) {
    console.error(e)
    return null;
  }
}

const updateWindowHub = (hub) => {
  if (!hub?.id) return;

  window.currentHubId = hub?.id;
  window.currentHubCode = hub?.code;
  window.currentHubName = hub?.name;
  window.currentHubAddress = hub?.address;
  window.currentHubAddressString = hub?.address_string;
}

const sessionSlice = createSlice({
  name: 'session',
  initialState: {
    currentUser: {},
    currentHub: window.currentHubId ?
      {
        id: window.currentHubId,
        name: window.currentHubName,
        code: window.currentHubCode,
        address: window.currentHubAddress,
        address_string: window.currentHubAddressString,
      } : null,
    locationUpdatedInSession: safeSerializeLocalStorage('locationUpdatedInSession') || false,
    nearestDeliverySlot: { id: null, label: null },
    onlyPickup: false,
    isLoggedIn: false,
    overlayState: safeSerializeLocalStorage('overlayState') || { visible: true } // if admin overlay is shown at the bottom
  },
  reducers: {
    userAuthenticationSuccess: (state, action) => {
      state.currentUser = action.payload;
      state.isLoggedIn = true;

      if (!state.currentUser.hub_id && state.currentHub?.id) {
        state.currentUser.hub = { id: state.currentHub.id, name: state.currentHub.name, code: state.currentHub.code };
        state.currentUser.hub_id = state.currentHub.id
      }
      // TODO: SsetNearestDeliverySlotynchronize with angular UserService
    },
    userAuthenticationError: (state, action) => {
      const error = action.payload;
      console.log(action, error);
      state.isLoggedIn = false;

      setTimeout(() => {
        Alerts.info("Fehler beim Anmelden.");
      });
      // TODO: Synchronize with angular UserService
    },
    setCurrentHub: (state, action) => {
      state.currentHub = action.payload;

      if (state.currentUser.id) {
        state.currentUser = {
          ...state.currentUser,
          hub_id: action.payload.id,
          hub: {
            id: action.payload.id,
            code: action.payload.code,
            name: action.payload.name
          }
        };
      }
      updateWindowHub(action.payload);
    },
    setNearestDeliverySlot: (state, action) => {
      state.nearestDeliverySlot = action.payload.slot;
      state.onlyPickup = action.payload.only_pickup;
    },
    setOverlayState: (state, action) => {
      if (window.localStorage) {
        window.localStorage.Farmy_overlayState = JSON.stringify(action.payload);
      }

      state.overlayState = action.payload;
    },
    setFavorites: (state, action) => {
      state.currentUser = {...state.currentUser, favorite_ids: [...action.payload]};
    },
    setLocationUpdatedInSession: (state, action) => {
      if (window.localStorage) {
        window.localStorage.Farmy_locationUpdatedInSession = JSON.stringify(action.payload);
      }
      state.locationUpdatedInSession = action.payload;
    },
    setPreferenceAddresses: (state, action) => {
      state.currentUser = {...state.currentUser, preference_addresses: [...action.payload]};
    },
    setShipAddresses: (state, action) => {
      state.currentUser = {...state.currentUser, ship_addresses: [...action.payload]};
    },
    setBillAddresses: (state, action) => {
      state.currentUser = {...state.currentUser, bill_addresses: [...action.payload]};
    }
  }
});

export const authenticateUser = (dispatch, email, password) => {
  UserService.authenticate(email, password).then(result => {
    dispatch(userAuthenticationSuccess(UserService.currentUser));
  }, error => {
    dispatch(userAuthenticationError(error));
  })
}

// Associates session with a hub
// Saves the address in user's preference addresses if the param is passed. This param accepts an object or a string.
// Updates user's hub and, if provided, updates the order too.
export const associateByAddress = (dispatch, address = {}, hubId, currentOrder) => {
  const params = {
    current_order_id: currentOrder?.id,
    order_token: currentOrder?.token,
    address,
    hub_id: hubId
  };

  return axios.post('/api/frontend/hubs/associate.json', params).then(response => {
    if (response.data.hub) {
      const address = response.data.saved_address || null;
      addPreferenceAddress(dispatch, address, 'ship');
      dispatch(setShipAddress(address));
      if (!isValidAddress(address)) {
        dispatch(setAddressHandled(false));
      }
      dispatch(setCurrentHub(response.data.hub));
      loadNearestDeliveryTime(dispatch, response.data.hub_id);
    }
    return response.data;
  });
};

export const addPreferenceAddress = (dispatch, address, preferredFor) => {
  const user = store.getState().session.currentUser;
  if (!user?.id || !address) return;

  let preferenceAddresses = [];
  let setAddresses;
  if (preferredFor === 'ship') {
    preferenceAddresses = user.ship_addresses;
    setAddresses = setShipAddresses;
  } else {
    preferenceAddresses = user.bill_addresses;
    setAddresses = setBillAddresses;
  }
  dispatch(setAddresses([address, ...preferenceAddresses.filter(a => !isSameAddress(a, address))]));
};

export const updateFavorites = (dispatch, favoritesID) => {
  dispatch(setFavorites(favoritesID));
};

/**
 * This is invoked by the angular UserService
 * @param dispatch
 * @param user
 */
export const consumeUserLoadedFromSession = (dispatch, user) => {
  dispatch(userAuthenticationSuccess(user));
};

export const updateLocationUpdatedInSession = (dispatch, updatedInSession) => {
  return dispatch(setLocationUpdatedInSession(updatedInSession));
}

export const loadNearestDeliveryTime = async(dispatch, hubId) => {
  const checkout = store.getState().checkout;
  const currentHub = store.getState().session.currentHub;
  const shipAddress = checkout.shipAddress;
  hubId ||= currentHub?.id;

  const params = {};
  if (shipAddress?.lat || shipAddress?.latitude) params.lat = shipAddress.lat || shipAddress.latitude;
  if (shipAddress?.lng || shipAddress?.longitude) params.lng = shipAddress.lng || shipAddress.longitude;
  if (hubId) params.hub_id = hubId;

  const response = await axios.get('/api/farmy/delivery_slots/nearest_delivery_date', {params});

  window.cartBlocked = !response.data.slot;

  dispatch(setNearestDeliverySlot(response.data));
};

export const setSessionReturnTo = (url) => {
  return axios.post('/sessions/set_return_to.json', {return_to: url});
}

// Login service as a custom hook.
export const useExternalLogin = () => {
  const location = useLocation();

  const redirectToExternalLogin = (request) => {
    axios.get("/login_url").then(response => {
      if (request === 'login' && response.data && response.data.login_url) {
        window.location.href = response.data.login_url
      }
      if (request === 'registration' && response.data && response.data.registration_url) {
        window.location.href = response.data.registration_url
      }
    })
  }

  const onLoginRequested = (request) => {
    if (location?.pathname && !['/welcome', '/activation'].includes(location.pathname)) {
      setSessionReturnTo(`${location.pathname}${location.search}`).then(_ => {
        redirectToExternalLogin(request);
      }, error => {
        redirectToExternalLogin(request);
      })
    } else {
      redirectToExternalLogin(request);
    }
  }

  return {
    onLoginRequested
  }
}

export const {
  userAuthenticationSuccess,
  userAuthenticationError,
  setCurrentHub,
  setNearestDeliverySlot,
  setFavorites,
  setLocationUpdatedInSession,
  setShipAddresses,
  setBillAddresses
} = sessionSlice.actions;

export default sessionSlice.reducer;
