import {createSlice} from '@reduxjs/toolkit';
import axios from "axios";
import {setMinimumOrderValue} from "./cart";

/*
TODO: REMOVE EXAMPLES
setCurrentTranslations,
setTranslationState,
setCurrentLocale*/

const trackingSlice = createSlice({
    name: 'tracking',
    initialState: {
        mapOptions: {},
        map: null,
        distance: null,
        onRoute: true,
        shipped: false,
        centeredFirstTime: false,
        updateLocationInterval: 7000,
        courier: {
            location: {lat: 0, lng: 0},
            label: 'o',
            marker: null
        },
        destination: {
            label: 'd',
            marker: null
        }
    },
    reducers: {
        setMapOptions: (state, action) => { state.mapOptions = action.payload },
        setMap: (state, action) => { state.map = action.payload },
        setDistance: (state, action) => { state.distance = action.payload },
        setOnRoute: (state, action) => { state.onRoute = action.payload },
        setShipped: (state, action) => { state.shipped = action.payload },
        setCenteredFirstTime: (state, action) => { state.centeredFirstTime = action.payload },
        setCourier: (state, action) => { state.courier = action.payload },
        setDestination: (state, action) => { state.destination = action.payload }
    }
});

export const initializeMap = (dispatch) => {
    const mapOptions = {
        defaultCenter: true,
        center: new google.maps.LatLng(52.531677, 13.381777),
        zoom: 16,
        disableDefaultUI: false,
        styles: [{"featureType":"water","elementType":"geometry","stylers":[{"color":"#e9e9e9"},{"lightness":17}]},{"featureType":"landscape","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":20}]},{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#ffffff"},{"lightness":17}]},{"featureType":"road.highway","elementType":"geometry.stroke","stylers":[{"color":"#ffffff"},{"lightness":29},{"weight":0.2}]},{"featureType":"road.arterial","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":18}]},{"featureType":"road.local","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":16}]},{"featureType":"poi","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":21}]},{"featureType":"poi.park","elementType":"geometry","stylers":[{"color":"#dedede"},{"lightness":21}]},{"elementType":"labels.text.stroke","stylers":[{"visibility":"on"},{"color":"#ffffff"},{"lightness":16}]},{"elementType":"labels.text.fill","stylers":[{"saturation":36},{"color":"#333333"},{"lightness":40}]},{"elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"geometry","stylers":[{"color":"#f2f2f2"},{"lightness":19}]},{"featureType":"administrative","elementType":"geometry.fill","stylers":[{"color":"#fefefe"},{"lightness":20}]},{"featureType":"administrative","elementType":"geometry.stroke","stylers":[{"color":"#fefefe"},{"lightness":17},{"weight":1.2}]}]
    };

    dispatch(setMapOptions(mapOptions))
}

export const {
    setMapOptions,
    setMap,
    setDistance,
    setOnRoute,
    setShipped,
    setCenteredFirstTime,
    setCourier,
    setDestination,
} = trackingSlice.actions;

export default trackingSlice.reducer;
