import * as _ from 'underscore';
import store from '../react-store/store';
import {
  currentCartLoaded,
  loadCartProducts,
  setCartLoading
} from '../react-store/reducers/cart';
import {
  associateByAddress,
} from '../react-store/reducers/session';
import axios from 'axios';
import UnitsInflector from '../shared-services/units-inflector';
import {ngRootScope} from '../angular-integration';
import {deferredPromise} from '../utils/misc';
import UserService from './user-service-rx';
import Alerts from './alerts-rx';
import {setIncompleteOrdersPopupVisible} from '../react-store/reducers/shared';
import {isBlankAddress} from '../react-store/helpers/AddressHelper';

const ahoy = window.ahoy

const CartData = (() => {
  let emptyCart = {
    id: window.currentOrderId,
    number: window.currentOrderNumber,
    token: window.currentOrderToken,
    line_items: []
  };

  const $rootScope = ngRootScope;

  const $scope = this || {};
  let lowCapacityPopupVisible = false;

  let oldCart = null;

  let updateCancelFunction; // Placeholder for the cart update request canceller
  let cartLoadInterval;

  $scope.cart = {...emptyCart};
  $scope.isLoading = false;
  $scope.isUpdating = false;
  $scope.updatesSynced = true;
  $scope.itemCount = 0;
  $scope.oldLocale = I18n.locale;
  $scope.checkedOtherCarts = false;

  /**
   * When cart blocked is true, the client
   * will abort any attempt to modify cart contents,
   * reverting it to the last known state.
   *
   * This appeared as part of the 2020 covid crisis solution
   * to lower system load.
   *
   * @type {boolean}
   */
  $scope.cartBlocked = false;

  /**
   * Unless it is true, loading an order in complete state
   * will trigger a cart reset/reload, so that a complete order cannot
   * be modified
   * @type {boolean}
   */
  $scope.allowCompleteCart = false;

  /**
   * Will contain a reference to current ABO weekly, if the session is in ABO mode
   * @type {null}
   */
  $scope.currentWeekly = null;

  $scope.doUpdateCart = true;

  /**
   * Placeholder for translated text tokens that might be
   * used in a view
   * @type {{}}
   */
  $scope.translationData = {
    minimum_order_value_formatted: null
  };

  /**
   * Cache of product variants data, to be reused when working
   * with cart directly via CartData API without passing product
   * variant descriptions.
   *
   * @type {{}}
   */
  $scope.productDataCache = {};

  // Disable updates upon a specific parameter, for testing
  if (location.search.indexOf('disabled_cart_update') > -1)
    $scope.doUpdateCart = false;

  $scope.resetCart = function() {
    return new Promise((resolve, reject) => {
      axios.get('/api/frontend/orders/current.json').then(response => {
        $scope.cart.id = response.data.order.id;
        $scope.cart.number = response.data.order.number;
        $scope.cart.token = response.data.order.token;

        window.currentOrderId = $scope.cart.id;
        window.currentOrderNumber = $scope.cart.number;
        window.currentOrderToken = $scope.cart.token;

        $scope.load().then(() => {
          resolve()
        })
      }, e => reject(e));
    })
  };

  $scope.load = function() {
    $scope.isLoading = true;
    store.dispatch(setCartLoading(true));

    populateOldCart();

    return new Promise((resolve, reject) => {
      if ($scope.currentWeekly == null) {
        if (currentOrderNumber == null) {
          reject("Order is empty");
        }

        $scope.loadRequestTimeout = deferredPromise();
        const params = {
          order_token: currentOrderToken,
          locale: I18n.locale,
          no_variant_labels: 't'
        };

        axios.get(`/api/frontend/orders/${currentOrderNumber}/cart.json`, {params}).then(function(response) {
          if (!$scope.isUpdating) {
            $scope.cart = response.data.cart;

            if ($scope.cart == null) $scope.cart = {...emptyCart};

            // Check if the order is complete and should not be loaded
            if (response.data.cart.state == 'complete') {
              $scope.isUpdating = false;
              store.dispatch(setCartLoading(false));
              return $scope.resetCart();
            }

            window.currentOrderId = $scope.cart.id;
            window.currentOrderNumber = $scope.cart.number;
            window.currentOrderToken = $scope.cart.token;
            window.$ngRootScope.currentUserPurchasingSmartPass = $scope.cart.smart_pass_purchase_order;

            // $scope.translationData.minimum_order_value_formatted = "CHF " + $filter('pennies')($scope.cart.minimum_order_value);

            $scope.old_total = $scope.cart.total;

            $scope.productIds = _.map($scope.cart.line_items, i => i.product_id);

            $scope.updateAdjustments();

            enrichLineItemData();
            updateLegacyApi();
            updateSoftValidations();
            populateUpdatedProductIds();

            // Show multiple carts popup, if the last time we asked was more than half an hour ago
            // and we're not on a thank you page
            // Note: there are exceptions for the checkout and thank-you page
            if ($scope.incompleteOrders == null && !$scope.checkedOtherCarts && UserService.getSecondsSincePreferredCartOrderSet() > 2400 && window.CheckoutCtrl == null && location.href.indexOf('thankyou') == -1) {
              $scope.checkedOtherCarts = true;

              if (store.getState().session.isLoggedIn && location.href.indexOf("/payments") == -1) { // do not check multiple carts in supplier portal mode or on payment pages
                setTimeout(() => {
                  $scope.backgroundReloadIncompleteAndNotifyOnMultipleOrders();
                }, 3000)
              }
            }

            $rootScope.$broadcast('cartdata:loaded');
            loadCartProducts(store.dispatch, $scope.cart).then(products => {
              store.dispatch(currentCartLoaded({cart: JSON.parse(JSON.stringify($scope.cart)), products: products}));
            })
          }

          $scope.isLoading = false;
          store.dispatch(setCartLoading(false));
          resolve($scope.cart);
        }, function(response) {
          $scope.isLoading = false;
          store.dispatch(setCartLoading(false));
          reject(response);
        }).catch(function() {
          console.log("Promise Rejected");
        }).finally(function() {
          $scope.isLoading = false;
          store.dispatch(setCartLoading(false));
        });
      } else {
        $scope.isLoading = false;
        store.dispatch(setCartLoading(false));
        resolve($scope.cart);
      }
    })
  };

  $scope.save = function(options) {
    let deferred = deferredPromise();
    store.dispatch(setCartLoading(true));

    if ($scope.currentWeekly == null) {
      $scope.isUpdating = true;
      populateOldCart();

      try {
        // Abort active load request, if there is any
        if ($scope.loadRequestTimeout) $scope.loadRequestTimeout.resolve();

        let mappedLineItems = _.map($scope.cart.line_items, function(lineItem) {
          return {
            product_id: lineItem.product_id,
            variant_id: lineItem.variant.id,
            quantity_in_units: lineItem.variant.quantity_in_units,
            referrer: lineItem.referrer
          }
        });

        let updateParams = {
          order_token: currentOrderToken,
          line_items: mappedLineItems,
          no_variant_labels: 't',
          session_hub_id: store.getState().session.currentHub?.id
        };

        if (updateCancelFunction) updateCancelFunction();

        const canceller = deferredPromise();
        updateCancelFunction = () => { canceller.resolve() };

        $scope.lastSaveRequestStartedAt = moment();

        const source = axios.CancelToken.source();

        if (options?.cancelTokenPlaceholder) {
          options.cancelTokenPlaceholder.source = source;
        }

        if ($scope.previousCancelSource != null) $scope.previousCancelSource.cancel();
        $scope.previousCancelSource = source;

        axios.patch('/api/frontend/orders/' + ($scope.cart && $scope.cart.number ? $scope.cart.number : 'null') + '/cart.json', updateParams, { cancelToken: source.token }).then(function(response) {
          updateLegacyApi();

          if ($scope.cart && response.data.cart) {
            // Do a full update if the cart is not initialized yet
            $scope.cart = { ...$scope.cart, ...response.data.cart };
            // $scope.translationData.minimum_order_value_formatted = `${window.defaultCurrency} ` + $filter('pennies')($scope.cart.minimum_order_value);

            if (response.data.cart.minimum_order_value)
              $scope.cart.minimum_order_value = parseFloat(response.data.cart.minimum_order_value);

            window.currentOrderId = $scope.cart.id;
            window.currentOrderNumber = $scope.cart.number;
            window.currentOrderToken = $scope.cart.token;

            if (response.data.cart.line_items)
              $scope.productIds = _.map(response.data.cart.line_items, i => i.product_id);
          }

          $scope.updateAdjustments();

          enrichLineItemData();
          updateSoftValidations();
          populateUpdatedProductIds();

          $rootScope.$broadcast('cartdata:saved');

          if ($scope.cart.total != $scope.old_total && $scope.cart.item_total != $scope.old_item_total) {
            $scope.old_total = $scope.cart.total;
            $scope.old_item_total = $scope.cart.item_total;
          }

          if (response.data.update_cart_errors && response.data.update_cart_errors.length > 0) {
            Alerts.error(response.data.update_cart_errors.join(", "));
          }

          setTimeout(() => {
            $rootScope.$broadcast('cart:changed');
            loadCartProducts(store.dispatch, $scope.cart).then(products => {
              store.dispatch(currentCartLoaded({cart: JSON.parse(JSON.stringify($scope.cart)), products: products}));
              resetCartLoadInterval();
            })
          })

          store.dispatch(setCartLoading(false));
          $scope.updatesSynced = true;
          deferred.resolve();
        }, function(response) {
          store.dispatch(setCartLoading(false));
          deferred.reject(response);
        }).finally(function() {
          store.dispatch(setCartLoading(false));
          $scope.isUpdating = false;
          $scope.isLoading = false;
        });
      } catch(e) {
        deferred.reject(e);
        store.dispatch(setCartLoading(false));
        $scope.isUpdating = false;
        $scope.isLoading = false;
        // console.error(e);
      }
    } else {
      store.dispatch(setCartLoading(false));
      deferred.resolve();
    }

    return deferred.promise;
  };

  /**
   * Sets the variant of this product in the cart.
   * @param productId
   * @param variant Object with id, label, price, quantity_in_units keys
   * @returns {*}
   */
  $scope.setCartVariant = function(productId, variant, referrer, productOptions) {
    $scope.updatesSynced = false;

    let deferred = deferredPromise();

    if (window.cartBlocked) {
      $scope.showLowCapacityPopup();
      deferred.reject("cart_blocked");
      return deferred.promise;
    }

    if ($scope.currentWeekly == null) { // normal shopping mode
      populateOldCart();
      let lineItem = this.getLineItemForProduct(productId);

      if (lineItem == null && variant != null) { // Add to cart
        lineItem = {
          product_id: productId,
          quantity: 1,
          price: variant.price,
          total: variant.price,
          variant: variant,
          variant_id: variant.id,
          referrer: referrer,
          ax_primary_taxon: productOptions && productOptions.ax_primary_taxon ? productOptions.ax_primary_taxon : null,
          quantity_in_units: variant.qui || variant.quantity_in_units
        };

        if (updateCancelFunction) updateCancelFunction();

        $scope.cart.line_items = [...$scope.cart.line_items, lineItem];
        $scope.productIds = _.map($scope.cart.line_items, i => i.product_id);

        loadCartProducts(store.dispatch, $scope.cart).then(products => {
          store.dispatch(currentCartLoaded({cart: JSON.parse(JSON.stringify($scope.cart)), products: products}));
          resetCartLoadInterval();
          $rootScope.$broadcast('cart:product:added', lineItem.productData || { id: productId });
          trackCartEvent('order-item-added', {product_id: productId, variant_id: variant.id})
        })
      } else if (variant == null && lineItem != null) { // Remove from cart
        if (updateCancelFunction) updateCancelFunction();
        $scope.cart.line_items = _.without($scope.cart.line_items, _.findWhere($scope.cart.line_items, {product_id: lineItem.product_id}));
        $scope.productIds = _.map($scope.cart.line_items, i => i.product_id);
        trackCartEvent('order-removed-item', {product_id: lineItem?.product_id, variant_id: lineItem?.variant_id})
      } else if (lineItem != null) { // Update line item
        if (updateCancelFunction) updateCancelFunction();

        const oldQuantity = lineItem.quantity_in_units;

        lineItem = {
          ...lineItem,
          variant: {...variant},
          price: variant.price,
          total: variant.price,
          quantity_in_units: variant.qui || variant.quantity_in_units,
          quantity: variant.qui || variant.quantity_in_units,
          variant_id: variant.id
        };

        const trackingParams = {
          product_id: lineItem?.product_id,
          variant_id: lineItem?.variant_id,
          old_quantity: oldQuantity,
          new_quantity: lineItem?.quantity_in_units
        }
        trackCartEvent('order-item-changed', trackingParams)
      } else {
        // console.warn("Invalid cart change for", productId, variant);
      }

      // Attempt to cancel a loading cart update request,
      // if any
      if ($scope.previousCancelSource != null) {
        $scope.previousCancelSource.cancel();
        $scope.previousCancelSource = null;
      }

      const updatedLineItems = $scope.cart.line_items.map(item => {
        return item.product_id === lineItem.product_id ? lineItem : item;
      });
      $scope.cart.line_items = [...updatedLineItems];
      $scope.cart.updated_at = moment().format();
      $scope.lastLocalUpdateAt = moment();

      store.dispatch(currentCartLoaded({cart: JSON.parse(JSON.stringify($scope.cart))}));
      store.dispatch(setCartLoading(true)); // Keep the cart in loading state, until it's actually saved

      populateUpdatedProductIds();
      $rootScope.$broadcast('cart:changed');

      deferred.resolve({lineItem});
    } else { // weekly item list mode

    }

    return deferred.promise;
  };

  $scope.getLineItemForProduct = function(productId) {
    return $scope.cart.line_items.find(li => li.product_id == productId);
  };

  $scope.containsProduct = function(productId) {
    if (!$scope.cart || $scope.cart.line_items.length == 0) {
      return false;
    }

    return _.any($scope.cart.line_items, function (line_item) {
      return line_item.product_id == productId;
    });
  };

  $scope.updateAdjustments = function() {
    $scope.cart.non_zero_adjustments = _.reject($scope.cart.adjustments, a => a.amount == 0);
  };

  $scope.updateTotal = function() {
    if ($scope.currentWeekly == null) {
      $scope.cart.total = 0;
      $scope.cart.item_total = 0;
      const shipTotal = $scope.cart.ship_total ? parseFloat($scope.cart.ship_total) : 0;

      _.each($scope.cart.line_items, function(lineItem) {
        let val;

        val = parseFloat(lineItem.price); // + parseFloat(lineItem.vat_amount); VAT is included in the price

        if (!lineItem.hidden) {
          $scope.cart.item_total += val;
        }

        $scope.cart.total += val;
      });

      // Deduct tax adjustments
      $scope.cart.total = $scope.cart.total + shipTotal + $scope.cart.adjustments.filter(a => a.originator_type != "Spree::TaxRate").reduce((memo, a) => memo + a.amount, 0);
    } else {
      // $scope.cart.total = WeeklyCartService.getTotal();
      // $scope.cart.item_total = WeeklyCartService.getTotal();
    }
  };

  $scope.synchronized = function () {
    return $scope.updatesSynced;
  };

  $scope.updating = function () {
    return $scope.isUpdating;
  };

  $scope.needsSave = function () {
    if (!$scope.lastLocalUpdateAt) {
      return false;
    }

    if (!$scope.lastSaveRequestStartedAt || $scope.lastLocalUpdateAt > $scope.lastSaveRequestStartedAt) {
      // Check time to last update, if it was less than a some hundreds of milliseconds ago,
      // cancel the update, because another fast update maybe incoming
      if (moment() - $scope.lastLocalUpdateAt < 650) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  $scope.setCurrentOrder = function(order) {
    window.currentOrderId = order.id;
    window.currentOrderNumber = order.number;
    window.currentOrderToken = order.token;

    $scope.cart = { ...emptyCart };
    $scope.cart.number = order.number;
    $scope.cart.id = order.id;
    $scope.cart.token = order.token;

    return new Promise((resolve, reject) => {
      // Current order changed in the session as well
      axios.patch(`/api/frontend/orders/${$scope.cart.number}/current.json`, { order_token: $scope.cart.token }, { withCredentials: true }).then(response => {
        let encodedSession = response.data.encoded_session;
        let newCurrentOrderStruct = response.data.order;
        let newLocationAddress = response.data.location;
        let newLocationHub = response.data.hub;

        // Update user Session
        if (encodedSession && !UserService.loggingOut) {
          // UserService.updateCurrentSessionCookie(encodedSession);
        }

        const currentHubId = store.getState().session.currentHub.id;
        // const currentOrder = store.getState().cart.currentOrder

        // If the new order's hub doesn't match the current session hub,
        // we have to change it. Depending on whether the target order
        // has a shipping address or not, it can be done in two different ways:
        if (newCurrentOrderStruct.hub_id != currentHubId) {
          if (newLocationAddress?.google_maps_address) {
            // Assign new hub by address, using the actual order address
            associateByAddress(store.dispatch, { description: newLocationAddress.google_maps_address }, newCurrentOrderStruct.hub_id, newCurrentOrderStruct).then(() => {
              $scope.load().then(() => {
                resolve($scope.cart);
              }, e => {
                reject(e)
              })
            })
          } else {
            // Assign new hub without passing an address if order doesn't have a ship address
            associateByAddress(store.dispatch, {}, newCurrentOrderStruct.hub_id, newCurrentOrderStruct).then(() => {
              $scope.load().then(() => {
                resolve($scope.cart);
              }, e => {
                reject(e)
              })
            })
          }
        } else {
          // If hub isn't changing, then we just reload the cart and that's it
          $scope.load().then(() => {
            resolve($scope.cart);
          }, e => {
            reject(e)
          })
        }
      }, e => {
        reject(e)
      });
    })
  };

  $scope.startUpdating = function() {
    $scope.doUpdateCart = true;
  };

  $scope.stopUpdating = function() {
    $scope.doUpdateCart = false;
  };

  // Public API for usage by connected native mobile clients

  $scope.getProductDataById = function(productId) {
    return new Promise(function(resolve, reject) {
      if ($scope.productDataCache[productId]) {
        resolve($scope.productDataCache[productId]);
      } else {
        axios.get(sprintf('/api/frontend/products/%s.json?include_unaddable=t', productId)).then(function(response) {
          $scope.productDataCache[productId] = response.data.product;
          resolve($scope.productDataCache[productId]);
        }, function(error) {
          reject(error);
        })
      }
    });
  };

  $scope.setProductCount = function(productId, variantQuantityIndex, referrer) {
    let lineItem = this.getLineItemForProduct(productId);

    return new Promise(function(resolve, reject) {
      $scope.getProductDataById(productId).then(function(productData) {
        let variant = _.find(productData.variants, function (v) {
          return v.quantity_index == variantQuantityIndex;
        });

        if (variant) {
          CartData.setCartVariant(productId, {
            id: variant.id,
            price: variant.price,
            quantity_in_units: variant.quantity_in_units,
            quantity_index: variant.quantity_index
          }, referrer);
        } else if (variant == null && variantQuantityIndex == 0) {
          CartData.setCartVariant(productId, null);
        } else {
          // Already at maximum capacity per product
          // TODO: Notify?
          reject('maxcount');
          return;
        }

        resolve(variant);
      });
    });
  };

  $scope.setProductQuantity = function(productId, quantityInUnits, referrer) {
    let lineItem = this.getLineItemForProduct(productId);

    return new Promise(function(resolve, reject) {
      $scope.getProductDataById(productId).then(function(productData) {
        let variant = _.find(productData.variants, function (v) {
          return v.quantity_in_units == quantityInUnits;
        });

        if (variant) {
          CartData.setCartVariant(productId, {
            id: variant.id,
            price: variant.price,
            quantity_in_units: variant.quantity_in_units,
            quantity_index: variant.quantity_index
          }, referrer);
        } else if (variant == null && quantityInUnits == 0) {
          CartData.setCartVariant(productId, null);
        } else {
          // Already at maximum capacity per product
          // TODO: Notify?
          reject('maxcount');
          return;
        }

        resolve(variant);
      });
    });
  };

  $scope.increaseProductCount = function(productId, referrer) {
    let lineItem = CartData.getLineItemForProduct(productId);
    let nextIndex = lineItem ? lineItem.variant.quantity_index + 1 : 1;

    return new Promise(function(resolve, reject) {
      $scope.getProductDataById(productId).then(function(productData) {
        let maxVariantIndex = _.max(_.map(productData.variants, function(v) { return v.quantity_index }));
        let variant

        if (nextIndex <= maxVariantIndex) {
          variant = _.find(productData.variants, function(v) {
            return v.quantity_index == nextIndex;
          });

          CartData.setCartVariant(productId, {
            id: variant.id,
            price: variant.price,
            quantity_in_units: variant.quantity_in_units,
            quantity_index: variant.quantity_index
          }, referrer);
        } else {
          // Already at maximum capacity per product
          // TODO: Notify?
          reject('maxcount');
          return;
        }

        resolve(variant);
      });
    });
  };

  $scope.decreaseProductCount = function(productId, removeAll) {
    return new Promise(function(resolve, reject) {
      let lineItem = CartData.getLineItemForProduct(productId);

      if (!lineItem) {
        reject();
        return;
      }

      let nextIndex = lineItem.variant.quantity_index > 1 ? lineItem.variant.quantity_index - 1 : 0;

      $scope.getProductDataById(productId).then(function(productData) {
        let variant = _.find(productData.variants, function(v) {
          return v.quantity_index == nextIndex;
        });

        if (removeAll || nextIndex == 0 || variant == null) {
          CartData.setCartVariant(productId, null);
          trackCartEvent('order-removed-item', {product_id: productId, variant_id: variant?.id})
          resolve();
        } else {
          CartData.setCartVariant(productId, {
            id: variant.id,
            price: variant.price,
            quantity_in_units: variant.quantity_in_units,
            quantity_index: variant.quantity_index
          });

          let trackingParams = {
            product_id: productId,
            variant_id: variant?.id,
            old_quantity: lineItem?.quantity_in_units,
            new_quantity: variant?.quantity_in_units
          }
          trackCartEvent('order-item-changed', trackingParams)

          resolve(variant);
        }
      });
    });
  };

  $scope.removeLineItem = function(lineItem) {
    return new Promise((resolve, reject) => {
      populateOldCart();

      let i = $scope.cart.line_items.indexOf(lineItem);
      $scope.cart.line_items.splice(i, 1);
      $scope.cart.updated_at = moment().toString();

      $scope.updateTotal();

      axios.post(`/api/frontend/orders/${$scope.cart.number}/remove_line_item.json`, { product_id: lineItem.product_id, order_token: $scope.cart.token }).then(response => {
        $scope.cart =  { ...$scope.cart, ...response.data.cart };
        $scope.productIds = _.map($scope.cart.line_items, i => i.product_id);
        $scope.updateAdjustments();
        populateUpdatedProductIds();
        $rootScope.$broadcast('cartdata:saved');
        $rootScope.$broadcast('cart:changed');

        trackCartEvent('order-removed-item', {product_id: lineItem?.product_id, variant_id: lineItem?.variant_id})

        resolve($scope.cart);
      }, (e) => {
        Alerts.error(errorMessage(e));
        reject(e);
      })
    })
  };

  $scope.loadIncompleteOrders = function({ onlyRecent }) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/frontend/orders/incomplete.json?exclude_order_id=${$scope.cart.id}&exclude_zero=t&no_addresses=t&no_payments=t&no_shipments=t&no_adjustments=t&no_min_order_value=t&no_delivery_slot=t&no_state_specific=t&line_item_count=t${onlyRecent ? '&only_recent=t' : ''}`).then(response => {
        // Check if there's more than one order that is not the current
        let orders = (response.data && response.data.orders) || [];
        let currentOrder = { ...$scope.cart };
        currentOrder.isCurrent = true;
        currentOrder.line_item_count = currentOrder.line_items && currentOrder.line_items.length;

        // Check if the order is already on the list (may happen during automatic cart switch)
        let alreadyIn = _.find(orders, o => o.id == currentOrder.id);

        if (alreadyIn) {
          alreadyIn.isCurrent = true;
        } else orders.splice(0, 0, currentOrder); // Add current order to the collection on top

        // A hack to let the user_menu in the header and mobile sidebar now
        // that there are multiple carts for the session
        UserService.hasOtherCartOrders = true;

        $scope.incompleteOrders = orders;

        resolve(orders);
      }, e => reject(e));
    })
  };

  /**
   * Reloads incomplete orders, changes to the optimal cart (if any) or shows a popup
   */
  $scope.backgroundReloadIncompleteAndNotifyOnMultipleOrders = function() {
    $scope.loadIncompleteOrders({ onlyRecent: true }).then(orders => {
      let shouldIgnoreMultipleOrdersNotification = false;
      // IMPORTANT HIDDEN WORKFLOW:
      // Detect if there's just one incomplete order with items,
      // while the current order is empty. Then switch automatically
      // to the other order. If there are more options - show the popup
      let incompleteOrdersPopupDismissedByUserAt = store.getState().shared.incompleteOrdersPopupDismissedByUserAt;
      if (incompleteOrdersPopupDismissedByUserAt !== null && incompleteOrdersPopupDismissedByUserAt !== 0) {
        incompleteOrdersPopupDismissedByUserAt = parseInt(incompleteOrdersPopupDismissedByUserAt);
        if (!isNaN(incompleteOrdersPopupDismissedByUserAt)) {
          const tNow = (new Date()).getTime();
          const elapsed = (tNow - incompleteOrdersPopupDismissedByUserAt) / 1000;
          if (elapsed < 15 * 60) {
            console.info(`Ignoring multiple orders notification, because the last notification happened just ${elapsed / 60} minutes ago.`);
            shouldIgnoreMultipleOrdersNotification = true;
          }
        }
      }
      if (orders.length === 2 && !shouldIgnoreMultipleOrdersNotification) {
        let otherOrder = orders.find(o => !o.isCurrent);

        if (otherOrder && otherOrder.item_total !== 0 && CartData.cart.item_total === 0 && (CartData.cart.line_items == null || CartData.cart.line_items.length === 0)) {
          // Swap current empty order with the only other non-empty option:
          console.info('Switching current empty cart to order: ', otherOrder.number);
          CartData.setCurrentOrder(otherOrder);
        } else {
          if (!window.location.pathname.includes('/checkout')) $scope.notifyOnMultipleCartOrders(orders);
        }
      } else if ((orders.length > 2 || orders.find(o => o.item_total > 0 && o.id !== $scope.cart.id)) && !shouldIgnoreMultipleOrdersNotification) {
        if (!window.location.pathname.includes('/checkout')) $scope.notifyOnMultipleCartOrders(orders);
      }
    })
  };

  /**
   * Just reloads incomplete orders and shows the popup
   *
   * @returns {*}
   */
  $scope.reloadIncompleteAndNotifyOnMultipleCartOrders = function() {
    return new Promise((resolve, reject) => {
      $scope.loadIncompleteOrders().then(orders => {
        if (orders.length == 2) {
          let otherOrder = orders.find(o => !o.isCurrent);

          if (otherOrder && otherOrder.item_total != 0 && CartData.cart.item_total == 0 && (CartData.cart.line_items == null || CartData.cart.line_items.length == 0)) {
            // Swap current empty order with the only other non-empty option:
            console.info("Switching current empty cart to order: ", otherOrder.number);
            CartData.setCurrentOrder(otherOrder)
          } else if (window.location.pathname.indexOf('checkout') == -1) {
            $scope.notifyOnMultipleCartOrders(orders)
          }
        } else if (orders.length > 2 || orders.find(o => o.item_total > 0 && o.id != $scope.cart.id)) {
          if (window.location.pathname.indexOf('checkout') == -1) $scope.notifyOnMultipleCartOrders(orders)
        }

        resolve(orders);
      }, e => reject(e));
    });
  };

  /**
   * Looks for alternative cart-state orders that may be presented to
   * the user to select as the 'current' order. Will display a popup,
   * if such alternative orders with recent update timestamp are located.
   */
  $scope.notifyOnMultipleCartOrders = function(orders) {
    return new Promise((resolve, reject) => {
      store.dispatch(setIncompleteOrdersPopupVisible({visible: true, orders}));
      resolve([]);
    });
  }

  /**
   * Switches CartData to working with weekly contents
   * instead of the current order. Used when modifying a weekly from a
   * weekly edit page.
   *
   * @param weekly
   */
  $scope.setWeeklyMode = function(weekly) {
    // WeeklyCartService.currentWeekly = weekly;
    // $scope.currentWeekly = weekly;

    $scope.updateTotal();
    // $scope.itemCount = WeeklyCartService.currentWeekly.items.length;
  };

  $scope.leaveWeeklyMode = function() {
    // if (WeeklyCartService != null) WeeklyCartService.currentWeekly = null;
    $scope.currentWeekly = null;
    $scope.itemCount = $scope.cart.line_items ? $scope.cart.line_items.length : 0;
    $scope.updateTotal();
  };

  $scope.emptyCart = function() {
    $scope.isClearingCart = true;
    $scope.isUpdating = true;

    const variantIds = $scope.cart.line_items.map(li => li.variant_id);

    return axios.get('/cart/empty').then(result => {
      $scope.load().then(result => {
        store.dispatch(currentCartLoaded({cart: JSON.parse(JSON.stringify($scope.cart))}));
        $rootScope.$broadcast('cart:changed');
        trackCartEvent('cart-emptied', {variant_ids: variantIds})
      }, e => {})
    }, e => {
      Alerts.error(errorMessage(e));
    }).finally(() => {
      $scope.isUpdating = false;
    })
  };

  $scope.showLowCapacityPopup = function() {
    if (lowCapacityPopupVisible) return false;

    lowCapacityPopupVisible = true;

    console.info("TODO: Show Low Slot Capacity Popup");

    return true;
  };

  /**
   * Filters the adjustment to get a list of only those, that must be shown below order totals,
   * create some virtual adjustments to summarize tax categories
   *
   * @param currentCart An optional cart-interface object that is not the current scope cart
   * @returns {*[]|*}
   */
  $scope.calculateOrderTaxAdjustments = (currentCart) => {
    const virtualAdjustments = [];

    if (currentCart == null) currentCart = $scope.cart;

    if (currentCart.adjustments) {
      currentCart.adjustments.forEach(adjustment => {
        // item VAT
        if (adjustment.adjustable_type == 'Spree::LineItem' && adjustment.originator_type == "Spree::TaxRate") {
          const existing = virtualAdjustments.find(a => a.originator_type == "Spree::TaxRate" && a.originator_id == adjustment.originator_id);

          if (existing) {
            existing.amount += parseFloat(adjustment.amount);
          } else {
            virtualAdjustments.push({
              id: -1,
              adjustable_type: 'Spree::Order',
              adjustable_id: currentCart.id,
              originator_type: adjustment.originator_type,
              originator_id: adjustment.originator_id,
              label: adjustment.label.replace(': Lebensmittel', ''),
              amount: parseFloat(adjustment.amount),
              order_id: currentCart.id
            })
          }
        } else if (adjustment.adjustable_type == 'Spree::LineItem' && adjustment.pfand) {
          const existing = virtualAdjustments.find(a => a.adjustable_type == 'Spree::Order' && a.pfand);

          if (existing) {
            existing.amount += parseFloat(adjustment.amount);
          } else {
            virtualAdjustments.push({
              id: -1,
              adjustable_type: 'Spree::Order',
              adjustable_id: currentCart.id,
              originator_type: adjustment.originator_type,
              originator_id: adjustment.originator_id,
              label: "Pfand",
              amount: parseFloat(adjustment.amount),
              order_id: currentCart.id,
              pfand: true
            })
          }
        } else if (adjustment.adjustable_type == 'Spree::Order' && adjustment.included) {
          virtualAdjustments.push({...adjustment});
        }
      })
    } else {
      // console.warn("currentCart.adjustments not defined");
    }

    if (currentCart.non_zero_adjustments) {
      return virtualAdjustments.concat(currentCart.non_zero_adjustments.filter(a => {
        return a.adjustable_type == 'Spree::Order' && virtualAdjustments.find(a2 => a2.id == a.id) == null
      }));
    } else {
      return virtualAdjustments.concat([]);
    }
  };

  $scope.checkPrices = async(currentCart) => {
    await axios.get(`/api/checkouts/${currentCart.number}?order_token=${currentCart.token}&check_prices=t`);
  };

  //
  // Private members
  //

  function trackCartEvent(name = '', options = {}) {
    if (!name || !name.length) return;
    if (!ahoy) return;

    try {
      const sortMode = store.getState().catalog.currentSortMode;

      const properties = {
        order_id: $scope.cart.id,
        order_number: $scope.cart.number,
        hub_id: window.currentHubId,
        sort_mode: sortMode,
        channel: window.xSessionChannel,
        user_id: window.currentUserId,
        ...options
      };

      if (window.ahoy) window.ahoy.track(name, properties);
    } catch (error) {
      console.error(error);
    }
  }

  // Copy the contents of current cart for later comparison.
  function populateOldCart() {
    oldCart = {
      items: $scope.cart && $scope.cart.line_items ? { ...$scope.cart.line_items } : null,
      productIds: $scope.productIds
    }
  }

  /**
   * Creates an array of "updatedProductIds" and appends it to the cart object.
   * Both new or removed products, along with those with quantity changes.
   * */
  function populateUpdatedProductIds() {
    let commonProductIds = _($scope.productIds).intersection(oldCart.productIds);
    let needAttentionIds = _(_($scope.productIds)
      .union(oldCart.productIds))
      .difference(commonProductIds);

    let updatedItemIds = _(_(_($scope.cart.line_items)
      .filter(item => commonProductIds.indexOf(item.product_id) > -1))
      .select((item) => {
        let oldItem = _(oldCart.items).find(i => i.product_id == item.product_id);
        return oldItem == null || item.quantity_in_units != oldItem.quantity_in_units
      }))
      .map(item => item.product_id);

    $scope.cart.updatedProductIds = _(updatedItemIds).union(needAttentionIds);
  }

  function enrichLineItemData() {
    return new Promise((resolve, reject) => {
      // Calculate current variant labels
      $scope.cart.line_items.forEach((item, num) => {
        if (item.variant && item.variant.label == null) {
          UnitsInflector.$inflect(item.variant.quantity_in_units, item.unit_name).then(label => {
            item.variant.label = label;

            if (item.price && item.price.toFixed) {
              item.variant.price_label = `${label} CHF ${item.price.toFixed(2)}`
            } else {
              item.variant.price_label = `${label} CHF ${item.price}`
            }
          })
        }
      });

      // Update taxons
      let itemsToUpdate = _.select($scope.cart.line_items, item => item.ax_primary_taxon == null);

      if (itemsToUpdate.length == 0) {
        resolve();
      } else {
        axios.get(`/api/frontend/products/category_info.json`, { params: { ids: _.map(itemsToUpdate, i => i.product_id).join(',') }}).then(response => {
          if (response.data.length && response.data.length > 0) {
            _.each(response.data, productCategorization => {
              _.each($scope.cart.line_items, item => {
                if (item.ax_primary_taxon == null && item.product_id == productCategorization.product_id) {
                  item.ax_primary_taxon = productCategorization.category_taxon;
                }
              })
            })
          }

          resolve();
        }, e => {
          // reject(e); // does it make sense to reject here?
          resolve();
        })
      }
    })
  }

  $scope.enrichLineItemData = enrichLineItemData;

  /**
   * Sets some soft validation flag on cart items, like hub-compatibility
   */
  function updateSoftValidations() {
    if ($scope.cart.line_items == null) return;

    _.each($scope.cart.line_items, item => {
      item.isHubIncompatible = item.hub_ids && item.hub_ids.indexOf($scope.cart.hub_id) == -1; // post-lost check of hub compatibility
    });
  }
  if (Rails.env == 'development') $scope.updateSoftValidations = updateSoftValidations; // for debugging

  function updateLegacyApi() {
    FarmyCartApi.cartItems = _.map($scope.cart.line_items, function(li) { return { variant_id: li.variant.id }});
  }

  function reloadCart() {
    if (!$scope.doUpdateCart && UserService.loggingOut) { return }

    if($scope.cart && $scope.updatesSynced && !$scope.isUpdating && !$scope.isLoading) {
      $scope.load();
    }
  }

  function saveCartIfChanged() {
    if ($scope.cart && $scope.needsSave() && !$scope.isUpdating && !window.cartBlocked) {
      setTimeout(() => {
        $scope.save();
      })
    }
  }

  let resetCartLoadInterval = () => {
    if (window.browserIsBot || window.prerenderAgent || window.supplierPortalMode) return;

    if (cartLoadInterval)
      clearInterval(cartLoadInterval);

    cartLoadInterval = setInterval(() => {
      if (!window.cartBlocked) reloadCart()
    }, 13000);
  }

  if (!window.browserIsBot && !window.prerenderAgent && !window.supplierPortalMode) {
    resetCartLoadInterval();
    setInterval(saveCartIfChanged, 200);
  }

  AngularIntegration.$on('user:logout', (event) => {
    setTimeout(() => {
      $scope.itemCount = 0;
      $scope.resetCart().then(function() {
      });
    })
  });

  AngularIntegration.$on('checkout:complete', (event) => {
    setTimeout(() => {
      CartData.cart = { ...emptyCart };
      $scope.itemCount = 0;
      $scope.resetCart();
    })
  });

  AngularIntegration.$on('hubs:changed', (event) => {
    setTimeout(() => {
      $scope.load();
    }, 500)
  });

  window.CartData = $scope;

  return $scope;
})();

export default CartData;
