const ThemeFarmy = {
  name: 'farmy',
  dimensions: {
    mobile: {
      maxWindowWidth: 766,
      header: {
        height: 45
      },
      productItem: {
        cartHeight: 175
      }
    },
    desktop: {
      productItem: {
        cartHeight: 199
      }
    }
  },
  fonts: {
    body: "",
    product_item: { // minNameLength: fontSize (in pixels)
      full: {
        60: 12,
        45: 14,
        35: 15,
        0: 16
      },
      mobile_cards: {
        60: 11,
        45: 12,
        35: 13,
        0: 14
      },
      other: {
        65: 12,
        50: 13,
        0: 14
      }
    },
    product_ribbon: {
      full: {
        0: 100,
        10: 85,
        15: 80,
        20: 70,
        25: 65,
        30: 60
      },
      mobile_cards: {
        0: 120,
        10: 95,
        13: 85,
        17: 80,
        20: 70
      }
    }
  },
  colors: {
    themePrimary: '#8b254c',
    themeBlack: '#343a40',
    themeBlackMuted: '#888888',
    themeDarkGrey: '#3d3d39',
    themeTertiary: '#b5ce34',
    themeBright: '#FFFEF7',
    themeSecondary: '#ef7c00',
    themeRed: '#e52320',
    themePriceBlue: '#0074BC',
    textColorPrimary: '#8b254c',
    textColor: '#585858',
    priceColor: '#32702e',
    successColor: '#32702e',
    brandSuccessColor: '#b5ce34',
    textColorInverted: '#ffffff',
    invertedBg: '#8b254c',
    invertedBgDarker: '#691c38',
    primaryBg: '#F26F00',
    primaryBgTextColor: '#FFFFFF'
  }
};

export default ThemeFarmy
