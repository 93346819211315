import farmy from './Theme.farmy';

// Deep merge for nested objects.
// replaceKeys (array) for keys that will be directly replaced.
const deepAssign = (obj1, obj2, replaceKeys=[]) => {
  for (let key in obj2) {
    if (replaceKeys.indexOf(key) == -1 && typeof(obj1[key]) == 'object' && typeof(obj2[key]) == 'object')
      deepAssign(obj1[key], obj2[key], replaceKeys)
    else
      obj1[key] = obj2[key]
  }

  return obj1
}

export const currentTheme = () => {
  let themes = {farmy};

  let currentTheme = window.currentStorefront || 'farmy';
  return currentTheme == 'farmy' ? themes[currentTheme] : deepAssign(themes['farmy'], themes[currentTheme], ['full', 'mobile_cards', 'other']);
}

export default currentTheme
