export const BlankAddressObject = {id: null, address1: '', house_number: '', city: '', phone: '', company: '', gender: '', firstname: '', lastname: '', zipcode: '', lat: null, lng: null};
export const hubPhoneNumbers = (window.knownHubs || []).map(h => h.address.phone).filter(p => p);

export const isBlankAddress = (address) => {
  if (!address) return true;

  return [address.address1, address.house_number, address.zipcode, address.city, address.phone].every(val => !val);
};

export const formatAddress = (address) => {
  return `${address.address1} ${address.house_number}, ${address.zipcode} ${address.city}`;
};

export const isAnonymous = (address) => {
  return !address || !address.firstname || !address.lastname;
};

export const isValidAddress = (address, order = null) => {
  if (!address) return false;

  let isValid = isCompleteAddress(address);
  if ((order && order.delivery_mode === "deliver" && address.preferred_for === "ship") || address.preferred_for === "bill") {
    isValid &&= !isHubAddress(address);
  }
  return isValid;
};

export const isSameAddress = (address, anotherAddress) => {
  if (!address || !anotherAddress) return false;
  return ['address1', 'house_number', 'city'].every(key => address[key] === anotherAddress[key]);
};

export const isHubAddress = (address) => {
  if (address.is_hub) return true;

  return window.knownHubs?.some(hub => isSameAddress(hub.address, address));
};

export const hasValidPhone = (address) => {
  if (!address?.phone) return false;

  let isValid = /^[+]?[0-9]{5,15}$/.test(address.phone);
  isValid &&= !hubPhoneNumbers.includes(address.phone);
  return isValid;
};

export const isCompleteAddress = (address) => {
  return address && ["gender", "firstname", "lastname", "address1", "house_number", "zipcode", "city", "phone"].every(val => address[val]);
};
