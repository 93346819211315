let currentInflections = window.unitsInflections;

const UnitsInflector = {
  $inflect: (amount, unit) => {
    return new Promise((resolve, reject) => {
      resolve(UnitsInflector.inflect(amount, unit));
    })
  },

  inflect: (amount, unit) => {
    if (amount == null) {
      if (window.Rails && window.Rails.env != 'production') console.warn("Attempt to inflate a null amount");
      amount = 0; // normalize null
    }

    if (currentInflections == null)
      return `${amount} ${unit}`;

    const key = Object.keys(currentInflections).find((i) => currentInflections[i].one == unit || currentInflections[i].other == unit || i == unit);
    const pair = currentInflections[key];

    if (pair == null) {
      // console.warn(`Could not inflect amount ${amount} for unit '${unit}'`);
      return `${amount} ${unit}`;
    }

    if (amount == 1) {
      return `${amount.toString().replace(/\.0$/, '')} ${pair.one}`
    } else if (pair && amount != 1) {
      return `${amount.toString().replace(/\.0$/, '')} ${pair.other}`
    } else return `${amount.toString().replace(/\.0$/, '')} ${unit}`;
  }
}


export default UnitsInflector;