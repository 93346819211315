import React from 'react'
import LoaderSvg from "../../assets/loaders/DoubleRing-1.4s-257px.svg";

const LoadingStub = (props) => {
  const {size} = props;

  const parseSize = () => {
    if (!size) return;

    return size.toString().includes('px') ? size : `${size.toString()}px`;
  }

  let style = {
    display: "flex", height: props.height || "70vh", justifyContent: "center", alignItems: "center"
  }

  let parsedSize = parseSize();

  return <div style={style}>
    <div style={{width: parsedSize || 257, height: parsedSize || 257}}>
      <object
        type="image/svg+xml"
        data={LoaderSvg}
        style={{background: "transparent", width: parsedSize || 257, height: parsedSize || 257}}
      ></object>
    </div>
  </div>
}

export default LoadingStub;