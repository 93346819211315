import axios from "axios";
import {currentLocale} from "../shared-services/translate";

const TaxonHelper = (() => {
  let $scope = this || {};

  $scope.CATEGORIES_ROOT_TAXON_ID = window.rootCategoriesTaxonId;
  $scope.CATEGORIES_ROOT_TAXON = window.rootCategoriesTaxon;
  $scope.CATEGORIES_ROOT_TAXONOMY_ID = 6;

  $scope.taxonCache = {};
  $scope.searchTaxonsCache = null;

  window.TaxonHelper = $scope;

  $scope.getCompleteTaxonCache = function() {
    return new Promise((resolve, reject) => {
      if (window.searchTaxonsCache) {
        $scope.searchTaxonsCache = window.searchTaxonsCache;
        resolve(window.searchTaxonsCache);
      } else {
        axios.get(apiUrl("/taxons/flatten.json")).then(function(response) {
          if (response.data.taxons) {
            window.searchTaxonsCache = $scope.searchTaxonsCache = response.data.taxons;
            resolve(window.searchTaxonsCache);
          }
        }, function(error) {
          window.searchTaxonsCache = [];
          reject(error);
        });
      }
    })
  };

  /**
   * Gets a taxon data structure, caches it.
   *
   * @param taxonId
   * @param options { ignore_cache: 'true|false : Will ignore cache', description: 'true|false : Will load an extended description' }
   * @returns {*}
   */
  $scope.getTaxonInfo = function(taxonId, options) {
    var arrayResult = false;

    if (taxonId instanceof Array) {
      taxonId = taxonId.join(',');
      arrayResult = true;
    }

    return new Promise((resolve, reject) => {
      var params = {
        id: taxonId,
        locale: currentLocale(),
        description: (options && options.description == true) ? "t" : null
      };

      let ignoreCache = options && options.ignore_cache;

      if (options == null) options = {};

      if (options.include_direct_children) params.include_direct_children = true;
      if (options.seo_url) params.seo_url = true;
      if (options.alternate_seo_urls) params.alternate_seo_urls = true;

      // Return from cache when possible
      if (!ignoreCache && $scope.taxonCache && $scope.taxonCache[window.I18n.locale] && $scope.taxonCache[window.I18n.locale][taxonId] &&
        (options.include_direct_children ? $scope.taxonCache[window.I18n.locale][taxonId].children : true) && // skip cache if some parameters weren't cached last time
        (options.seo_url ? $scope.taxonCache[window.I18n.locale][taxonId].seo_url : true)) {
        resolve($scope.taxonCache[window.I18n.locale][taxonId]);
      } else {
        axios.get(`/api/frontend/taxons/taxon_info?locale=${window.I18n.locale}`, { params: params }).then((response) => {
          ensureValidCache();
          $scope.taxonCache[window.I18n.locale][taxonId] = response.data.taxon;
          resolve($scope.taxonCache[window.I18n.locale][taxonId]);
        }, (e) => {
          reject(e);
        })
      }
    })
  };

  /**
   * Gets a taxon data structure, caches it.
   *
   * @param taxonId
   * @param options { ignore_cache: 'true|false : Will ignore cache', description: 'true|false : Will load an extended description', only_depth: 'int|null' : of all the IDs will return only those taxons, that match the specified depth }
   * @returns {*}
   */
  $scope.getTaxonInfos = function(taxonIds, options) {
    return new Promise((resolve, reject) => {
      let ignoreCache = options && options.ignore_cache;

      if (options == null) options = {};

      var results = []

      var unfetchedTaxons = {};
      taxonIds.forEach(id => unfetchedTaxons[id] = true);

      // Try to fetch as manu results from cache as possible
      taxonIds.forEach(taxonId => {
        if (!ignoreCache && $scope.taxonCache && $scope.taxonCache[window.I18n.locale] && $scope.taxonCache[window.I18n.locale][taxonId] &&
          (options.include_direct_children ? $scope.taxonCache[window.I18n.locale][taxonId].children : true) && // skip cache if some parameters weren't cached last time
          (options.seo_url ? $scope.taxonCache[window.I18n.locale][taxonId].seo_url : true)) {
          results.push($scope.taxonCache[window.I18n.locale][taxonId]);
          unfetchedTaxons[taxonId] = false;
        }
      });

      let unfetchedTaxonValues = Object.keys(unfetchedTaxons).map(k => unfetchedTaxons[k])
      let unfetchedTaxonPairs = Object.keys(unfetchedTaxons).map(k => [k, unfetchedTaxons[k]]);

      // Check if there are any taxons left to fetch from remote:
      if (unfetchedTaxonValues.filter(v => v == true).length > 0) {
        var params = {
          id: unfetchedTaxonPairs.map(pair => pair[1] && pair[0]).filter(v => v != null).join(','),
          locale: currentLocale(),
          description: (options && options.description == true) ? "t" : null,
          only_depth: (options && options.only_depth) ? options.only_depth : null,
          taxonomy_id: (options && options.taxonomy_id) ? options.taxonomy_id : null,
        };

        if (options.include_direct_children) params.include_direct_children = true;
        if (options.seo_url) params.seo_url = true;

        // Fetch remote and resolve
        axios.get(`/api/frontend/taxons/taxon_info?locale=${window.I18n.locale}`, { params: params }).then((response) => {
          ensureValidCache();
          (response.data.taxons ? response.data.taxons : [response.data.taxon]).forEach(taxonInfo => {
            $scope.taxonCache[window.I18n.locale][taxonInfo.id] = taxonInfo;
            results.push($scope.taxonCache[window.I18n.locale][taxonInfo.id]);
          });

          resolve(results);
        }, (e) => {
          reject(e);
        })
      } else {
        // We can resolve instantly
        resolve(results);
      }
    })
  }

  $scope.getLocalizedTaxonNameById = function(taxonId) {
    return new Promise((resolve, reject) => {
      return getTaxonTranslations().then(cache => {
        if (cache[taxonId]) {
          resolve(cache[taxonId])
        } else {
          reject(null)
        }
      })
    });
  };

  /**
   * Hold a dictionaly of taxon-by-id translations, will
   * hold multiple locales at once.
   *
   * @type {}
   */
  var taxonTranslationCache = {};
  var isLoadingTranslationCache = false;

  function ensureValidCache() {
    // Setup cache
    if ($scope.taxonCache[window.I18n.locale] == null) {
      $scope.taxonCache[window.I18n.locale] = {};
    }
  }

  function getTaxonTranslations() {
    return new Promise((resolve, reject) => {
      if (taxonTranslationCache[currentLocale()] != null) {
        resolve(taxonTranslationCache[currentLocale()])
      } else if (isLoadingTranslationCache == currentLocale()) {
        // Wait until the cache is loaded by a parallel request
        setTimeout(() => {
          if (taxonTranslationCache[currentLocale()])
            resolve(taxonTranslationCache[currentLocale()])
          else {
            getTaxonTranslations().then(res => resolve(res));
          }
        }, 100);
      } else {
        isLoadingTranslationCache = currentLocale();

        axios.get(`/api/frontend/locale/taxon_translations.json?locale=${currentLocale()}`).then(response => {
          taxonTranslationCache[currentLocale()] = response.data;
          resolve(taxonTranslationCache[currentLocale()]);
        }, e => reject(e)).finally(() => isLoadingTranslationCache = false);
      }
    });
  }

  return $scope;
})();

export default TaxonHelper;