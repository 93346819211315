/**
 * Resolves the promise when the conditionFunction returns true at least once.
 * Performs the conditionFunction with an interval specified in checkInterval
 * and resolves once it returns true.
 *
 * @param conditionFunction (required)
 * @param checkInterval (required)
 * @param timeout (optional)
 * @returns {Promise<unknown>}
 */
export const resolveOnCondition = (conditionFunction, checkInterval = 50, timeout = 60000) => {
  return new Promise((resolve, reject) => {
    if (checkInterval == null) checkInterval = 50;
    if (timeout == null) timeout = 60000;
    let elapsed = 0;

    const checkFunction = () => {
      if (conditionFunction()) {
        resolve();
      } else {
        elapsed += checkInterval;
        if (timeout != null && elapsed >= timeout) reject("timeout");
        else setTimeout(checkFunction, checkInterval);
      }
    };

    checkFunction();
  })
}