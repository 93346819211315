/* ========================================================================== */
/*                        Frontend GUI Root Component                        */
/* ========================================================================== */
/*

## Adding new pages

See:

 - react-pages/{type}... (create your new pages here)
 - react-app/routes/routes.js (add your new pages to these routes)

You may need to tinker with either LayoutResolver or the top-level routes
in this file to enabled a custom layout for some special pages.

## About angularjs-react binding:

https://softeng.oicr.on.ca/chang_wang/2017/04/17/Using-AngularJS-components-directives-in-React/
https://hackernoon.com/how-to-embed-angularjs-and-react-like-a-pro-5y1c31on

*/

import React, {useEffect, Suspense} from 'react';
import {Provider, useSelector} from 'react-redux';
import store from '../react-store/store';
import {BrowserRouter, useHistory} from "react-router-dom";
import LayoutResolver from "./layouts/LayoutResolver";
import '../react-styles/styles.scss';
import {initCartState} from "../react-store/reducers/cart";
import {fetchTaxons, initFilterSelection} from "../react-store/reducers/catalog";
import LoadingStub from "../react-components/shared/LoadingStub";
// import {loadTranslations} from "../react-store/reducers/translation";

const RjsFrontendApp = (props) => {
  const currentLocale = useSelector(state => state.translation.currentLocale);

  const rollbarConfig = {};
  if (typeof window.currentUserId === 'number' && !isNaN(window.currentUserId) && window.currentUserId > 0) {
    rollbarConfig.payload = {
      person: {
        id: window.currentUserId,
      },
    };
  }
  window.Rollbar && window.Rollbar.configure(rollbarConfig);

  useEffect(() => {
    window.moment && window.moment.locale(currentLocale); // Force locale right-away, since there's only one
    initCartState(store.dispatch);
    fetchTaxons(store.dispatch);
    initFilterSelection(store.dispatch);

    // loadTranslations(currentLocale, store.dispatch);
  }, [])

  return (
    <Provider store={store}>
      <Suspense fallback={<LoadingStub/>}>
        <BrowserRouter basename="/">
          <LayoutResolver {...props}/>
        </BrowserRouter>
      </Suspense>
    </Provider>
  )
}

export default RjsFrontendApp;
