import { createSlice } from '@reduxjs/toolkit';
import {useSelector} from "react-redux";
import axios from "axios";

const translationSlice = createSlice({
  name: 'translation',
  initialState: {
    currentTranslations: null,
    translationState: {
      cachedInjector: null,
      loadingTranslations: false,
      loadingPromise: null,
    },
    currentLocale: 'de'
  },
  reducers: {
    setCurrentTranslations: (state, action) => void (state.currentTranslations = action.payload),
    setTranslationState: (state, action) => void (state.translationState = action.payload),
    setCurrentLocale: (state, action) => void (state.currentLocale = action.payload)
  }
});

export const loadTranslations = (locale, dispatch) => {
  let translationState = {};

  translationState.loadingTranslations = true;

  translationState.loadingPromise = new Promise((resolve, reject) => {
    axios.get(`/api/frontend/locale/translations.json?lang=${locale}&locale=${locale}`).then(response => {
      translationState.currentTranslations = response.data;
      console.log("loadingTranslations", translationState.loadingTranslations);
      translationState.loadingTranslations = false;
      dispatch(setTranslationState(translationState))
      resolve(translationState.currentTranslations);
    }).catch(e => {
      console.error(e);
      translationState.loadingTranslations = false;
      translationState.loadingPromise = null;
      reject(e);
    })
  })

  dispatch(setTranslationState(translationState));

  return translationState.loadingPromise;
};

export const changeLocale = (locale, dispatch) => {
  dispatch(setCurrentLocale(locale));
};

const translate = (key, opts = {}) => {
  const translationState = useSelector(state => state.translation.translationState);

  if (translationState.currentTranslations == null) {
    return ""
  }

  let result = typeof(key) == 'string' && key.includes('.') ?
    key.split('.').reduce((o,i)=> o[i] || opts.fallback || i, translationState.currentTranslations) :
    currentTranslations[key]

  // Localeapp interpolation, same pattern as in Ruby => %{localVar}
  if (opts.localVars) {
    for(let key in opts.localVars) {
      result = result.replace(`%{${key}}`, opts.localVars[key])
    }
  }

  result = result.replace(/%{(.*?)}/g, '') // remove any pending vars.

  return result
};

export const useTranslation = (id, opts = {}) => {
  const translationState = useSelector(state => state.translation.translationState)
  let translation;
  let cancelled = false;

  if (translationState.currentTranslations == null) {
    loadTranslations(I18n.locale).then(() => {
      if (!cancelled) translation = translate(id, opts);
    })
  } else {
    translation = translate(id, opts);
  }

  return translation
}

export const { setCurrentTranslations, setTranslationState, setCurrentLocale } = translationSlice.actions;
export default translationSlice.reducer;
