import { createSlice } from '@reduxjs/toolkit';
import store from "../store";
import {shallowEqual} from "react-redux";
import axios from "axios";
import NotificationsService, {defaultDelay} from "../../react-services/notifications-service";

const notificationsEndpoint = '/api/frontend/notifications';

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState: {
    queue: [],
    latest: [],
    numOfUndelivered: 0,
    current: null
  },
  reducers: {
    setLatestNotificationsQueue: (state, action) => {
      state.latest = action.payload;
    },
    setNumOfUndeliveredNotifications: (state, action) => {
      state.numOfUndelivered = action.payload;
    },
    setCurrentNotification: (state, action) => {
      state.current = action.payload;
    },
    queueNotification: (state, action) => {
      let queue = [...state.queue];
      let notification = {...action.payload, dequeue: true};
      const notificationId = action.payload?.id;

      if (notificationId && queue.map(n => n.id))
        queue = queue.filter(n => n.id === notificationId);

      if (!notificationId) {
        const fakeId = Math.ceil(Math.random() * 10000 + 10000);
        notification.id = fakeId;
      }

      state.queue = [...queue, notification];
      },
    dequeueNotification: (state, action) => {
      const notificationId = action?.payload?.id;

      if (notificationId) state.queue = ([...state.queue]).filter(n => n.id !== notificationId);
    },
  }
});

export const setDelivered = (notifications) => {
  return new Promise((resolve, reject) => {
    notifications = Array.isArray(notifications) ? notifications : [notifications];
    const notificationIds = notifications.map(n => n?.id).filter(id => id);
    if (!notificationIds?.length) reject("No id passed.");

    const undeliveredNotifications = store.getState().notifications.numOfUndelivered;

    if (undeliveredNotifications > 0) {
      store.dispatch(setNumOfUndeliveredNotifications(undeliveredNotifications - 1))
    }

    axios.post(notificationsEndpoint + '/set_delivered_collection.json', {ids: notificationIds})
      .then((r) => {
          NotificationsService.loadNotifications().then(rr => resolve(rr.data));
        },
        function(error) {
          console.error(error);
          reject(error)
        })

    notifications.forEach(notification => store.dispatch(dequeueNotification(notification)))
  })
};

export const { setLatestNotificationsQueue, setNumOfUndeliveredNotifications, setCurrentNotification, queueNotification, dequeueNotification } = notificationsSlice.actions;
export default notificationsSlice.reducer;
