import {ngRootScope} from "../angular-integration";
import store from "../react-store/store";
import {setLatestNotificationsQueue, setNumOfUndeliveredNotifications} from "../react-store/reducers/notifications";
import axios from "axios";
import PusherService from "./pusher-service-rx";
import Alerts from "./alerts-rx";

export const defaultDelay = 5000;

const NotificationsService = (() => {
  let $scope = this || {};

  $scope.notificationsEndpoint = '/api/frontend/notifications';

  $scope.sessionUpdateChannel = null;
  $scope.undeliveredNotifications = 0;
  $scope.recentNotifications = 0;
  $scope.latestNotificationsQueue = [];
  $scope.latestNotificationsSecondsAgo = 172800; // 2 days

  $scope.pusherEvents = {};
  $scope.pusherEventNames = ['session-update', 'user-notification', 'engagement']; // 'user-notification' is not needed, since we send notifications through the 'notifications update' channel

  $scope.processNotifications = function(rawNotifications) {
    const notificationsQueue = store.getState().notifications.queue;

    $scope.latestNotificationsQueue = [];
    $scope.notifications = rawNotifications;

    $scope.undeliveredNotifications = 0;
    $scope.recentNotifications = 0;

    if ($scope.notifications.length > 0) {
      $scope.notifications.forEach(notification => {
        var notificationScope = {
          notification: notification
        };

        if(notification.message) {
          // notification.message_compiled = $interpolate(notification.message)(notificationScope);
          notification.message_compiled = notification.message;
        }

        if(notification.title) {
          // notification.title_compiled = $interpolate(notification.title)(notificationScope);
          notification.title_compiled = notification.title;
        }

        if (notification.created_seconds_ago) {
          if (notification.created_seconds_ago < 120 && notification.seen == null) {
            $scope.recentNotifications += 1;
          }
        }

        generateListStyles(notification);

        try {
          let farmyNotification = {
            id: notification.id,
            style: notification.template_style,
            contents: notification.contents,
            message: notification.title,
            showCloseBtn: true,
            timeout: notification?.timeout >= 0 ? notification.timeout : defaultDelay,
            createdDaysAgo: Math.floor(notification.created_seconds_ago / 86400),
            delivered: notification.delivered,
            noPopup: notification?.no_popup
          };

          if (!notification.delivered) {
            $scope.undeliveredNotifications += 1;

            // Show the latest notification, but don't automatically show the rest of them, if more than one
            if (!notificationsQueue.map(n => n.id).includes(notification.id)) {
              let message = notification.contents ? notification.contents.message : null
              if (message == null && notification.title) message = notification.title;
              if (message == null && notification.contents && notification.contents.title) message = notification.contents.title;

              Alerts.enqueue(farmyNotification);
            }
          }

          if (!notification.delivered ||(notification?.created_seconds_ago && notification.created_seconds_ago < $scope.latestNotificationsSecondsAgo)) {
            $scope.latestNotificationsQueue.push(farmyNotification);
          }
        } catch(e) {
          console.error(e);
        }
      })
    }

    ngRootScope.$broadcast('user-notifications:loaded');

    return $scope.notifications;
  }

  $scope.loadNotifications = function() {
    return new Promise((resolve, reject) => {
      if (window.isReactivationPage) {
        resolve([]);
        return
      }

      var params = {
        locale: I18n.locale,
        include_message: 't',
        last: 16
      };

      axios.get($scope.notificationsEndpoint + '.json', { params: params }).then(function(response) {
        $scope.processNotifications(response.data);
        if ($scope.latestNotificationsQueue?.length)
          store.dispatch(setLatestNotificationsQueue($scope.latestNotificationsQueue));

        store.dispatch(setNumOfUndeliveredNotifications($scope.undeliveredNotifications));
        resolve($scope.notifications)
      })
    });
  };

  $scope.setDelivered = function(notification) {
    if (notification.id == null) return false;

    notification.delivered_at = new Date();

    if ($scope.undeliveredNotifications > 0) {
      $scope.undeliveredNotifications -= 1;
    }

    axios.post($scope.notificationsEndpoint + '/' + notification.id + '/set_delivered.json')
      .then(() => { $scope.loadNotifications() },
      function(error) {
        console.log(error);
      })

    return true;
  };

  /////// Real-time Messaging //////

  $scope.bindPusherVisitorChannels = function() {
    const visitorId = ahoy.getVisitorId();

    if (visitorId) {
      const channelName = `user-channel-${visitorId}`;
      bindToEvents(channelName);
    }
  };

  $scope.bindPusherUserChannels = function() {
    if (window.UserService.currentUser && window.UserService.currentUser.id) {
      // console.info(`Will bind pusher channels for user ${window.UserService.currentUser.id} [events=${events.join(', ')}]`);

      const channelName = getPrivateChannelName(window.UserService.currentUser);

      bindToEvents(channelName);

      $scope.notificationsUpdateChannel = PusherService.bind(channelName, 'notifications-update', function (data) {
        if (window.Rails.env != 'production') console.log(`Pusher[${channelName}]:`, data)
        $scope.onNotificationsUpdateMessage(data);
      });
    }
  };

  $scope.unbindPusherUserChannels = function(event, options) {
    let previousUser = options.previousUser;

    if (previousUser && previousUser.id) {
      // console.info(`Will unbind pusher channels for user ${previousUser.id}`);
      const channelName = getPrivateChannelName(previousUser);

      unbindToEvents(channelName);
      $scope.sessionUpdateChannel = null;

      PusherService.unbind(channelName, 'notifications-update');
    }
  };

  $scope.unbindPusherVisitorChannels = function(event, options) {
    const visitorId = ahoy.getVisitorId();
    const channelName = `user-channel-${visitorId}`;

    unbindToEvents(channelName);
  };

  $scope.onUserChannelMessage = function(message) {
    // Do some message handling, depending on the data
    console.log("onUserChannelMessage", message);

    if (message.eventName == 'logout' && !window.UserService.loggingOut) {
      ngRootScope.$broadcast('user:logout:outside', { });
      // Trigger a page refresh with 3s delay for logout to be in effect in all open pages
      setTimeout(function () { location.reload(true) }, 3000);
    } else if (message.eventName.indexOf('engagement:') > -1) {
      ngRootScope.$broadcast(message.eventName, message.payload)
    } else if (message.eventName == 'custom_notification') {
      addNotificationFromMessage(message)
    }
  };

  function addNotificationFromMessage(payload) {
    var message = payload.contents ? payload.contents.message : null
    if (message == null && payload.title) message = payload.title;
    if (message == null && payload.contents && payload.contents.title) message = payload.contents.title;

    let farmyNotification = {
      style: payload.template_style,
      contents: payload.contents,
      message: message,
      showCloseBtn: payload.showCloseBtn == true ? true : false,
      timeout: typeof(payload.timeout) == 'number' ? payload.timeout : 10000,
      delay: typeof(payload.delay) == 'number' ? payload.delay : null
    };

    Alerts.enqueue(farmyNotification)
  }

  $scope.onNotificationsUpdateMessage = function(message) {
    $scope.loadNotifications();
  };

  function onUserLogin() {
    $scope.unbindPusherVisitorChannels();
    $scope.bindPusherUserChannels();
  }

  function onUserLogout(event, options) {
    $scope.unbindPusherUserChannels(event, options);
    $scope.bindPusherVisitorChannels();
  }

  function bindToEvents(channelName) {
    _($scope.pusherEventNames).each((eventName) => {
      $scope.pusherEvents[eventName] = PusherService.bind(channelName, eventName, function(data) {
        if (window.Rails.env != 'production') console.log(`Pusher[${channelName}]:`, data);

        $scope.onUserChannelMessage(data);
      });
    });
  }

  function unbindToEvents(channelName) {
    $scope.pusherEvents = {};

    _($scope.pusherEventNames).each((eventName) => {
      PusherService.unbind(channelName, eventName);
    });
  }

  function touchRecentNotifications(noApplyScope) {
    let oldVal = $scope.recentNotifications;

    if ($scope.notifications && $scope.notifications.length > 0) {

      $scope.recentNotifications = 0;

      _($scope.notifications).each(notification => {
        if (notification.created_seconds_ago != null && notification.created_seconds_ago < 120 && notification.seen == null) {
          $scope.recentNotifications += 1;
          notification.created_seconds_ago += 4;
        } else if (notification.created_seconds_ago != null) {
          notification.created_seconds_ago += 4;
        }
      });
    }

    if (oldVal != $scope.recentNotifications && noApplyScope != true && window.NgFrontendAppCtrl) {
      window.NgFrontendAppCtrl.$apply()
    }
  }

  function getPrivateChannelName(userData) {
    const userId = userData?.id || window.UserService.currentUser?.id;
    if (!userId) throw new Error('No user data available');

    return `user-channel-${userId}`;
  }

  /**
   * Calculates CSS classes for the event element in the
   * notifications list popup
   * @param notification
   */
  function generateListStyles(notification) {
    if (notification.event_type == "order_completed") {
      notification.event_list_class = "event-success";
      notification.icon_list_class = "fa fa-ok";
      notification.template_style = "order_completed";
    } else if (notification.event_type == "order_shipped") {
      notification.event_list_class = "event-success";
      notification.icon_list_class = "fa fa-truck";
      notification.template_style = "success";
    } else if (notification.event_type == "added_line_item_promotion" || notification.event_type == "added_line_item_promotion") {
      notification.event_list_class = "event-gift";
      notification.icon_list_class = "fa fa-tags";
      notification.template_style = notification.event_type;
    } else if (notification.event_type == "eggs_earned") {
      notification.event_list_class = "event-bonus";
      notification.icon_list_class = "fa fa-star";
      notification.template_style = notification.event_type;
    } else if (notification.event_type == "en_route") {
      notification.event_list_class = "event-tracking";
      notification.icon_list_class = "fa fa-location";
      notification.template_style = notification.event_type;
    } else if (notification.event_type == "success") {
      notification.event_list_class = "event-success";
      notification.icon_list_class = "fa fa-ok";
      notification.template_style = notification.event_type;
    } else if (notification.event_type == "increase") {
      notification.event_list_class = "event-success";
      notification.icon_list_class = "fa fa-plus";
      notification.template_style = "success";
    } else if (notification.event_type == "decrease") {
      notification.event_list_class = "event-danger";
      notification.icon_list_class = "fa fa-minus";
      notification.template_style = "info";
    } else if (notification.event_type == "error") {
      notification.event_list_class = "event-danger";
      notification.icon_list_class = "fa fa-times";
      notification.template_style = "error";
    } else if (notification.event_type == "danger") {
      notification.event_list_class = "event-danger";
      notification.icon_list_class = "fa fa-attention";
      notification.template_style = "error";
    } else if (notification.event_type == "free_products_pending") {
      notification.event_list_class = "event-gift";
      notification.icon_list_class = "fa fa-heart";
      notification.template_style = "info";
    } else if (notification.event_type == 'courier') {
      notification.template_style = 'info'
    } else {
      notification.event_list_class = "event-info";
      notification.icon_list_class = "fa fa-info-circled";
      notification.template_style = notification.event_type;
    }
  }

  function setNativeAppListener() {
    window.addEventListener('beforeinstallprompt', onNativeAppInstallPrompt);
  }

  function onNativeAppInstallPrompt(event) {
    // Prevent Chrome 67 and earlier from automatically showing the prompt

    event.preventDefault();
    // Stash the event so it can be triggered later.
    // NOTE: we're using a custom notification for this, but still keep reference to the event just in case.
    $scope.nativeAppDeferredPrompt = event;
    // Send notification
    let params = {
      timeout: 0,
      style: 'install_android_app',
      delay: 1000
    }
    Alerts.enqueue(params);

    // Remove listener
    window.removeEventListener('beforeinstallprompt', onNativeAppInstallPrompt)
  }

  // Initialize
  // $rootScope.$on('cart:changed', $scope.loadNotifications);

  // Also watch the global currentOrderId: this way 'thankyou' page will display 'egg notifications'
  // $rootScope.$watch(()=> { return $window.currentOrderId }, ()=> { $scope.loadNotifications() });

  ngRootScope.$on('user:loaded', onUserLogin);
  ngRootScope.$on('user:authenticated', onUserLogin);
  ngRootScope.$on('user:logout', onUserLogout);

  let iTouchRecentNotifications = setInterval(touchRecentNotifications, 4000);

  if (!window.browserIsBot) {
    setInterval(() => {
      if (window.cartBlocked) return; // do not update when cart is blocked
      $scope.loadNotifications()
    }, 60000); // update once in a minute
  }

  ngRootScope.$on('$destroy', () => {
    if (iTouchRecentNotifications) clearInterval(iTouchRecentNotifications);
  });

  if (!window.browserIsBot) {
    setNativeAppListener();

    setTimeout(() => {
      if (!window.UserService.isLoggedIn) $scope.bindPusherVisitorChannels();
      $scope.loadNotifications();
    }, 1);
  }

  return $scope;
})();

window.NotificationsService = NotificationsService;

export default NotificationsService;
