const PusherService = (() => {
  const vm = this || {};
  vm.channels = [];

  vm.init = function (loadTries = 0) {
    if (vm.pusher) return vm.pusher;
    if (loadTries > 5) return;

    if (window.Pusher && !window.browserIsBot) {
      vm.pusher = new window.Pusher(window.Settings.PusherApiKey, {cluster: 'eu', encrypted: true});
    } else {
      loadTries++;
      setTimeout(() => vm.init(loadTries), 2000);
    }
    return vm.pusher;
  };

  vm.subscribe = function (name) {
    if (window.browserIsBot) return null;

    const fChannel = vm.findChannel(name);
    if (fChannel) return fChannel;

    const channel = {
      name: name,
      instance: vm.init().subscribe(name),
      binds: []
    };
    vm.channels.push(channel);
    return channel;
  };

  vm.bind = function (channelName, actionName, func) {
    if (window.browserIsBot) return null;

    const binded = _.find(vm.subscribe(channelName).binds, (c) => c === actionName);
    if (binded) return;

    vm.subscribe(channelName).binds.push(actionName);

    let channel = vm.subscribe(channelName).instance
      .bind(actionName, func);

    return channel
  };

  vm.unbind = function (channelName, actionName) {
    const fChannel = vm.findChannel(channelName);
    if (fChannel){
      fChannel.instance.unbind(actionName);
    }
  };

  vm.unsubscribe = function (channelName) {
    const fChannel = vm.findChannel(channelName);
    if (fChannel){
      _.forEach(fChannel.binds, (b) => {
        fChannel.instance.unbind(b);
      });
      vm.init().unsubscribe(channelName);
      vm.channels = _.without(vm.channels, (c) => {fChannel.name === c.name})
    }
  };

  vm.findChannel = function (name) {
    if (window.browserIsBot) return null;

    return _.find(vm.channels, (c) => c.name === name);
  };


  return vm;
})();

export default PusherService;