import * as _ from "underscore";
import {consumeUserLoadedFromSession} from "../react-store/reducers/session";
import store from "../react-store/store";
import {ngRootScope} from "../angular-integration";
import axios from "axios";
import {changeLocale} from "../shared-services/translate";
import Alerts from "./alerts-rx";
import NotificationsService from "./notifications-service";
import JsCookie from "../vendor/js-cookie";

const UserService = (() => {
  let $scope = this || {};
  let $localStorage = window.localStorage;

  const $rootScope = ngRootScope;

  window.UserService = $scope;

  $scope.currentUser = {};
  $scope.currentRoles = window.currentUserRoles || [];
  $scope.currentWeeklyId = window.currentWeeklyId;
  $scope.mode = window.currentWeeklyId == null ? 'normal' : 'weekly';
  $scope.currentUserId = window.currentUserId || null;
  $scope.isCorporateBuyer = false;
  $scope.isLoggedIn = $scope.currentUserId != null;
  $scope.isAdmin = false;
  $scope.isSupplier = false;
  $scope.activeSmartpass = null; // TODO
  $scope.hasUnderliveredOrders = false;

  try {
    $scope.preferredCartOrderSetAt = localStorage.userSessionpreferredCartOrderSetAt ? moment(localStorage.userSessionpreferredCartOrderSetAt).toDate() : moment().add(-360, 'days').toDate();
  } catch(e) {
    console.error(e);
    $scope.preferredCartOrderSetAt = moment().addDays(-360, 'days').toDate();
  }

  /**
   * Set by cart data when multiple cart orders are detected
   * @type {boolean}
   */
  $scope.hasOtherCartOrders = false;

  /**
   * Placeholder for UserAccount data (balance in cash, points)
   *
   * @type {object}
   */
  $scope.account = {};

  function deleteAllCookies() {
    var cookies = document.cookie.split(";");

    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i];
      var eqPos = cookie.indexOf("=");
      var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
  }

  function setCookie(cname, cvalue, expiration, domain) {
    // let expires = "expires="+ expiration.toUTCString();
    //
    if (domain) {
      JsCookie.set(cname, cvalue, { expires: expiration })
    } else {
      JsCookie.set(cname, cvalue, { expires: expiration, path: domain });
    }
  }

  $scope.authenticate = function(email, password) {
    return new Promise((resolve, reject) => {
      // TODO
      // $scope.currentUserId = ...
      axios.post('/login.json', { spree_user: { email: email, password: password }, noha: 't' }, { 'Authorization': null }).then((response) => {
        $scope.currentUser = { ...($scope.currentUser || {}), ...response.data.user };
        window.currentZipcode = window.currentZipcode || $scope.currentUser.last_used_zipcode
        window.currentUserId = $scope.currentUserId = response.data.user.id;
        window.currentUserHubId = response.data.user.hub_id;
        $scope.currentRoles.length = 0; // keep roles immutable
        _.each(response.data.roles, r => $scope.currentRoles.push(r));
        window.currentUserRoles = $scope.currentRoles;

        // Refresh authenticity token
        if (response.data.authenticity_token)
          $('meta[name=csrf-token]').attr('content', response.data.authenticity_token);

        checkRoles();
        checkFlags();
        NotificationsService.loadNotifications();

        axios.get('/users/my_cookies.html?noha=t').then(r => {
          $scope.isLoggedIn = true;
          // This event will trigger a hub refresh by the Hubs service
          $rootScope.$broadcast('user:authenticated', { user: $scope.currentUser, hub_id: window.currentUserHubId })
          resolve(response.data);
        });
      }, (error) => {
        $scope.currentUserId = null;
        $scope.isLoggedIn = false;
        Alerts.error(error.data.error);
        reject(error);
      });

    });
  };

  $scope.authenticateByToken = function(token) {
    return new Promise((resolve, reject) => {
      axios.post('/users/authenticate_by_token.json', { noha: 't' }, { headers: {
          'Authorization': 'Bearer ' + token
        } }).then(function(response) {
        $scope.currentUser = { ...($scope.currentUser || {}), ...response.data.user };
        window.currentZipcode = window.currentZipcode || $scope.currentUser.last_used_zipcode
        window.currentUserId = $scope.currentUserId = response.data.user.id;
        window.currentUserHubId = response.data.user.hub_id;
        $scope.currentRoles.length = 0; // keep roles immutable
        _.each(response.data.roles, r => $scope.currentRoles.push(r));
        window.currentUserRoles = $scope.currentRoles;

        // Refresh authenticity token
        if (response.data.authenticity_token)
          $('meta[name=csrf-token]').attr('content', response.data.authenticity_token);

        checkRoles();
        checkFlags();
        NotificationsService.loadNotifications();

        axios.get('/users/my_cookies.html').then(r => {
          $scope.isLoggedIn = true;
          $rootScope.$broadcast('user:authenticated', { user: $scope.currentUser, hub_id: window.currentUserHubId })
          resolve(response.data);
        });
      }, e => reject(e));
    })
  };

  $scope.signupUser = function(userData) {
    return new Promise((resolve, reject) => {
      userData.locale = $translate.use();
      axios.post('/api/signup.json', {spree_user: userData, noha: 't'}).then(function(response) {
        Alerts.success($translate.instant('user_registrations.signed_up'));
        $scope.jwtToken = response.data.token;
        $scope.deviseToken = response.data.devise_token;

        if (response.data.authenticity_token)
          $('meta[name=csrf-token]').attr('content', response.data.authenticity_token);

        $rootScope.$broadcast('user:registered', { userData: response.data });

        $scope.authenticate(userData.email, userData.password).then(userData => {
          resolve(userData);
        }, e => reject(e))
      }, function(error) {
        Alerts.error($translate.instant('cannot_perform_operation'));
      });
    })
  };

  $scope.logout = function() {
    $scope.loggingOut = true;

    let previousUser = UserService.currentUser;

    return axios.get('/user/spree_user/logout.json?noha=t').then(response => {
      // Clear localstorage
      window.localStorage.clear();

      // Delete cookies locally too
      var cookies = document.cookie.split(";");

      for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];
        var eqPos = cookie.indexOf("=");
        var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        if (name.indexOf('CSRF-TOKEN') > -1) continue; // keep the authenticity token for next requests
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
      }

      // TODO: Tell serviceworker to clear cache

      $scope.currentUser = {};
      $scope.currentUserId = null;
      $scope.isLoggedIn = false;
      $scope.currentRoles.length = 0;
      $scope.isAdmin = false;
      $scope.isCorporateBuyer = false;

      // Update authenticity token, if a new one has been received
      if (response.data.authenticity_token) {
        // $('meta[name=csrf-token]').attr('content', response.data.authenticity_token);
        // $cookies.put('CSRF-TOKEN', response.data.authenticity_token);
      }

      // At this point, there's a wrong CSRF-TOKEN stuck in the client-side session,
      // so we need make a GET request to get the new token
      axios.get('/users/my_cookies.html?noha=t').then(r => {
        // Set default hub
        $rootScope.$broadcast('user:logout', { user: $scope.currentUser, hub_id: window.currentUserHubId, previousUser: previousUser });

        $scope.loggingOut = false;
      }).finally(() => {
        $scope.loggingOut = false;
      })
    })
  };

  $scope.loadUser = function() {
    return new Promise((resolve, reject) => {
      let params = {
        // nocards: 't',
        // noshipping: 't',
        // TODO remove 'web' param when mobile apps use ship_addresses instead of preference_addresses and longer need this hack
        web: 't',
        noha: 't',
        force_active_user: location.search.includes('force_active_user=t') ? 't' : 'f'
      };

      axios.get('api/users/current.json', {params}).then(response => {
        let firstLoad = $scope.currentUser?.id == null;

        $scope.currentUser = response.data;
        window.currentZipcode = window.currentZipcode || $scope.currentUser.last_used_zipcode;
        window.currentUserId = $scope.currentUserId = $scope.currentUser.id;
        window.currentUserHubId = $scope.currentUser.hub_id;

        if (response.data.zipcode) window.currentZipcode = response.data.zipcode;

        $scope.currentRoles.length = 0; // keep roles immutable
        _.each(response.data.roles, r => $scope.currentRoles.push(r));
        checkRoles();
        checkFlags();

        $rootScope.$broadcast('user:loaded', { user: $scope.currentUser });
        if (firstLoad) $rootScope.$broadcast('user:firstload', { user: $scope.currentUser });

        consumeUserLoadedFromSession(store.dispatch, $scope.currentUser);

        setTimeout(() => {
          $scope.loadAccount();
        }, 3000);

        resolve();
      }, error => {
        // Force-unset authentication if the user could not be loaded
        $scope.isLoggedIn = false;
        $scope.currentUser = {};
        $scope.currentUserId = null;

        reject(error);
      })
    })
  };

  $scope.loadAccount = function() {
    return new Promise(function(resolve, reject) {
      axios.get(`/api/frontend/user_accounts/my.json?exclude_transactions=t`).then(function(response) {
        $scope.account = { ...$scope.account, ...response.data.account }
        resolve();
      });
    }).finally(function() {

    });
  };

  $scope.updateFilterPrefs = function(newPrefs) {
    if ($scope.currentUser && $scope.isLoggedIn) {
      return new Promise((resolve, reject) => {
        axios.post('/api/frontend/users/filter_prefs.json', { filter_prefs: newPrefs }).then(response => {
          $scope.loadUser();
          resolve(response.data)
        })
      })
    } else {
      return new Promise((resolve, reject) => {
        let filterPrefs = $localStorage.filterPrefs && JSON.parse($localStorage.filterPrefs);
        filterPrefs = filterPrefs || {};
        filterPrefs = { ...filterPrefs, ...newPrefs };
        $localStorage.filterPrefs =  JSON.stringify(filterPrefs);

        resolve(filterPrefs);
      })
    }

  };

  $scope.testCookies = function() {
    axios.post('/api/frontend/users/test_cookie.json').then(response => {

    })
  };

  /**
   * WARNING: This method currently corrupts the session cookie, the problem hasn't
   * been fixed yet...
   *
   * @param encodedSession
   */
  $scope.updateCurrentSessionCookie = function(encodedSession) {
    if (location.host) {
      var cookieHost = location.host.split(':')[0];

      let hostnameTokens = location.host.split(':')[0].split('.');

      if (hostnameTokens.length > 1) {
        hostnameTokens.shift();
        cookieHost = "." + hostnameTokens.join('.');
      }

      // setCookie(window.sessioncookiename, encodedSession, moment().add(window.sessioncookiettl, 'seconds').toDate(), null)
      setCookie(window.sessioncookiename, encodedSession, moment().add(window.sessioncookiettl, 'seconds').toDate(), cookieHost)
    } else {
      setCookie(window.sessioncookiename, encodedSession, moment().add(window.sessioncookiettl, 'seconds').toDate(), null)
    }
  }

  $scope.showLoginScreen = function() {
    // TODO: Special styles for mobile

    if (window.UserLoginViewCtrl || window.UserLoginPageCtrl) {
      console.log('Already at login screen');
      return;
    }

    let backToUrl = location.url;
  };

  $scope.changeLocale = function(locale) {
    changeLocale(locale);

    // Touch the user endpoint to update the locale:
    return new Promise((resolve, reject) => {
      axios.get(`/api/users/current.json?locale=${locale}&noaccount=t&nocards=t&noshipping=t&noorders=t`).then((r) => {
        resolve(r);
      })
    })
  };

  /**
   * This is related to 'select preferred order' modal behaviour. Each session
   * memorized the timestamp of the latest manual cart selection, to prevent
   * repetitive
   * @param date
   */
  $scope.setPreferredCartOrderSetAt = function(date) {
    $scope.preferredCartOrderSetAt = date;
    window.localStorage.userSessionpreferredCartOrderSetAt = moment(date).toString();
  };

  /**
   * Get a seconds diff for the last time when the user manually selected
   * a cart from the multiple carts selection popup.
   *
   * @returns {number}
   */
  $scope.getSecondsSincePreferredCartOrderSet = function() {
    if ($scope.preferredCartOrderSetAt == null) {
      return 3600 * 365;
    } else {
      return ((new Date()) - $scope.preferredCartOrderSetAt) / 1000.0
    }
  };

  $scope.resetPassword = function(email){
    return axios.post('/api/password/recover.json', {spree_user: { email: email }});
  };

  $scope.loginWithFacebook = function () {

  };

  // $scope.authenticateBySocialNetwork = function(strategy) {
  //   return new Promise((resolve, reject) => {
  //     $auth.authenticate(strategy).then((response) => {
  //       if (Rails.env == 'development') console.log("Facebook ograph", response);
  //
  //       if (response.error_fields || response.user_already_exists) {
  //         reject(response);
  //       }
  //       else if(response.need_to_sign_in) {
  //         axios.post('/authenticate_for_social_network.js', {spree_user: {email: response.data.user.email}}).then((response) => {
  //           $scope.currentUser = { ...($scope.currentUser || {}), ...response.data.user };
  //           window.currentZipcode = window.currentZipcode || $scope.currentUser.last_used_zipcode;
  //           window.currentUserId = $scope.currentUserId = response.data.user.id;
  //           window.currentUserHubId = response.data.user.hub_id;
  //           $scope.currentRoles.length = 0; // keep roles immutable
  //           _.each(response.data.roles, r => $scope.currentRoles.push(r));
  //           window.currentUserRoles = $scope.currentRoles;
  //
  //           // Refresh authenticity token
  //           if (response.data.authenticity_token)
  //             $('meta[name=csrf-token]').attr('content', response.data.authenticity_token);
  //
  //           checkRoles();
  //           checkFlags();
  //           NotificationsService.loadNotifications();
  //
  //           $http.get('/users/my_cookies.html').then(r => {
  //             $scope.isLoggedIn = true;
  //             // This event will trigger a hub refresh by the Hubs service
  //             $rootScope.$broadcast('user:authenticated', {user: $scope.currentUser, hub_id: window.currentUserHubId});
  //             resolve(response.data);
  //           });
  //         });
  //       }
  //       else {
  //         resolve(response)
  //       }
  //     });
  //   });
  // };

  // $scope.unlinkSocialNetwork = function(provider) {
  //   return $http.post('/api/users/unlink_social_network.json', {provider: provider, id: window.currentUserId})
  // };

  // Here's a collection of validators to use in address forms
  $scope.validators = {
    /**
     * ASYNCHRONOUS check for email uniqueness
     *
     * @param modelValue
     * @param viewValue
     * @returns {*}
     */
    emailTaken: function(modelValue, viewValue) {
      var value = modelValue || viewValue;

      return new Promise((resolve, reject) => {
        if (value == null || value.length <= 2) {
          resolve(true);
        } else {
          axios.get(`/check_email?email=${value}`).then(function(response) {
            if (response.data.exists) reject('taken');
            else resolve(true);
          }, error => {
            console.error(error);
            resolve(true);
          })
        }
      })
    },

    birthDateInvalid: function(modelValue, viewValue) {
      let value = modelValue || viewValue;
      let currentYear = moment().year();
      let year = parseInt(value);

      return new Promise((resolve, reject) => {
        if (value && value.length > 0 && isNaN(year)) {
          reject('invalid')
        } else if(!isNaN(year)) {
          if (currentYear - year < 16 || currentYear - year > 100) {
            reject('invalid')
          } else {
            resolve(true)
          }
        } else {
          resolve(true)
        }
      })
    },

    birthDate: function(modelValue, viewValue) {
      let value = modelValue || viewValue;

      return new Promise((resolve, reject) => {
        var date;
        var currentYear = moment().year();

        try {
          if (typeof(value) == 'string') date = new Date(value)
          else if (typeof(value) == 'object' && value._d) date = value.toDate(); // convert moment()
          else date = new Date(value);

          let year = date.getFullYear();

          if (isNaN(year)) reject({ invalid: true });
          else if (currentYear - year > 105 || currentYear - year <= 14) reject({ invalid: true });
          else resolve(true)
        } catch(e) {
          console.error(e);
          reject({ invalid: true })
        }
      })
    }
  };

  var loginScreenModalInstance = null;

  /**
   * Assigns public flags corresponding to certain roles
   */
  function checkRoles() {
    $scope.isAdmin = _.find($scope.currentRoles, r => r == 'admin' || r == 'developer') != null;
    $scope.isCorporateBuyer = _.find($scope.currentRoles, r => r == 'corporate_buyer') != null;
    $scope.isSupplier = _.find($scope.currentRoles, r => r == 'supplier') != null && $scope.currentUser.supplier_id ? true : false;
  }

  /**
   * Decodes additional data from the users/current.json reponse and sets appropriate scope flags
   */
  function checkFlags() {
    if ($scope.currentUser.pending_orders && $scope.currentUser.pending_orders.length > 0) {
      $scope.hasUnderliveredOrders = true;
    }
  }

  if ($scope.currentRoles && $scope.currentRoles.length > 0) checkRoles();
  if ($scope.currentUser && $scope.currentUser.id) checkFlags();

  // If the logged in flag is initially set,
  // it means the user was logged in since before load time
  // and we must load the user data
  if ($scope.isLoggedIn) {
    setTimeout(() => {
      $scope.loadUser()
    }, 0);
  }

  $rootScope.$on('user:balance:updated', $scope.loadAccount);
  $rootScope.$on('user:logout:outside', () => { $scope.logout() }); // react to logouts in another tab

  return $scope;
})();

export default UserService;
