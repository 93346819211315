import React, {useEffect, useState} from "react";
import axios from "axios";

const translationState = {
    cachedInjector: null,
    currentTranslations: null,
    loadingTranslations: false,
    loadingPromise: null,
};

export const useTranslate = () => {
    if (translationState.cachedInjector == null)
        translationState.cachedInjector = angular.element(document.getElementById("ng-root")).injector().get('$translate')
    return translationState.cachedInjector
};

export const useTranslation = (id, opts = {}) => {
    let translation;
    let cancelled = false;

    if (translationState.currentTranslations == null) {
        loadTranslations(I18n.locale).then(() => {
            if (!cancelled) translation = translate(id, opts);
        })
    } else {
        translation = translate(id, opts);
    }

    return translation
}

export const changeLocale = (locale) => {
    window.I18n.locale = locale;
}

export const currentLocale = () => {
    return window.I18n?.locale || 'de';
}

export const translate = (key, opts = {}) => {
    if (translationState.currentTranslations == null) {
        return ""
    }

    let result = typeof(key) == 'string' && key.includes('.') ?
      key.split('.').reduce((o,i)=> o[i] || opts.fallback || i, translationState.currentTranslations) :
      currentTranslations[key]

    // Localeapp interpolation, same pattern as in Ruby => %{localVar}
    if (opts.localVars) {
        for(let key in opts.localVars) {
            result = result.replace(`%{${key}}`, opts.localVars[key])
        }
    }

    result = result.replace(/%{(.*?)}/g, '') // remove any pending vars.

    return result
};

export const t = (key, defaultKey, opts = {}) => {
  const translation = translate(key, opts);

  return translation?.length ? translation : defaultKey;
};

const loadTranslations = (locale) => {
    if (translationState.loadingPromise) {
        return translationState.loadingPromise;
    }

    translationState.loadingTranslations = true;

    translationState.loadingPromise = new Promise((resolve, reject) => {
        axios.get(`/api/frontend/locale/translations.json?lang=${locale}&locale=${locale}`).then(response => {
            translationState.currentTranslations = response.data;
            console.log("loadingTranslations", translationState.loadingTranslations);
            translationState.loadingTranslations = false;
            resolve(translationState.currentTranslations);
        }).catch(e => {
            console.error(e);
            translationState.loadingTranslations = false;
            translationState.loadingPromise = null;
            reject(e);
        })
    })

    return translationState.loadingPromise;
};

export const TranslateAsync = ({id, opts}) => {
    const [translation, setTranslation] = useState("___na___");

    useEffect(() => {
        let cancelled = false;

        if (translationState.currentTranslations == null) {
            loadTranslations(I18n.locale).then(() => {
                if (!cancelled) setTranslation(translate(id, opts));
            })
        } else {
            setTranslation(translate(id, opts));
        }

        return () => {
            cancelled = true;
        }
    });

    let loadingStub = "";

    return <span>{translation == "___na___" ? loadingStub : translation }</span>
};

export default useTranslate;
