import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {createSlice} from '@reduxjs/toolkit';
import {currentTheme} from '../../react-themes/theme';
import axios from "axios";

const theme = currentTheme();
const maxMobileWindowWidth = theme.dimensions.mobile.maxWindowWidth;
let width = document.body.clientWidth;

export const initialLayout = width > maxMobileWindowWidth ? 'desktop' : 'mobile';

export const safeSerializeLocalStorage = (key) => {
  try {
    if (window.localStorage['Farmy_' + key] == null)
      return null;

    const existingValue = JSON.parse(window.localStorage['Farmy_' + key]);

    return existingValue;
  } catch(e) {
    console.error(e)
    return null;
  }
}

const sharedSlice = createSlice({
  name: 'shared',
  initialState: {
    layout: initialLayout,
    windowWidth: document.body.clientWidth,
    navSidebar: false,
    refreshNavSidebar: false,
    cartSidebar: false,
    userSidebar: false,
    accountMenuDropdown: false,
    filterSidebar: false,
    notificationsPopupVisible: false,
    locationPopupVisible: false,
    incompleteOrdersPopupVisible: false,
    incompleteOrdersPopupDismissedByUser: safeSerializeLocalStorage('incompleteOrdersPopupDismissedByUser') || false,
    incompleteOrdersPopupDismissedByUserAt: safeSerializeLocalStorage('incompleteOrdersPopupDismissedByUserAt') || null,
    loginPopupVisible: false,
    incompleteOrders: [],
    headerSearchOpen: false,
    lastSearchQuery: "",
    pageConfigurations: {}
  },
  reducers: {
    setLayout: (state, action) => void (state.layout = action.payload),
    setWindowWidth: (state, action) => void (state.windowWidth = action.payload),
    setNavSidebar: (state, action) => void (state.navSidebar = action.payload),
    setRefreshNavSidebar: (state, action) => void (state.refreshNavSidebar = action.payload),
    setCartSidebar: (state, action) => void (state.cartSidebar = action.payload),
    setUserSidebar: (state, action) => void (state.userSidebar = action.payload),
    setAccountMenuDropdown: (state, action) => void (state.accountMenuDropdown = action.payload),
    setFilterSidebar: (state, action) => void (state.filterSidebar = action.payload),
    setNotificationsPopupVisible: (state, action) => void (state.notificationsPopupVisible = action.payload),
    setLocationPopupVisible: (state, action) => void (state.locationPopupVisible = action.payload),
    setLoginPopupVisible: (state, action) => void (state.loginPopupVisible = action.payload),
    setIncompleteOrdersPopupVisible: (state, action) => {
      state.incompleteOrdersPopupVisible = action.payload.visible
      if (action.payload.orders)
        state.incompleteOrders = action.payload.orders;
    },
    setIncompleteOrdersPopupDismissedByUser: (state, action) => {
      const tNow = (new Date()).getTime();

      state.incompleteOrdersPopupDismissedByUser = action.payload;
      state.incompleteOrdersPopupDismissedByUserAt = tNow;

      if (window.localStorage) {
        window.localStorage.Farmy_incompleteOrdersPopupDismissedByUser = true;
        window.localStorage.Farmy_incompleteOrdersPopupDismissedByUserAt = tNow;
      }
    },
    setHeaderSearchOpen: (state, action) => void (state.headerSearchOpen = action.payload),
    setLastSearchQuery: (state, action) => void (state.lastSearchQuery = action.payload),
    addPageConfiguration: (state, action) => void (state.pageConfigurations[action.payload.name] = action.payload.data)
  }
});

export const {
  setLayout,
  setWindowWidth,
  setNavSidebar,
  setRefreshNavSidebar,
  setCartSidebar,
  setUserSidebar,
  setAccountMenuDropdown,
  setFilterSidebar,
  setNotificationsPopupVisible,
  setLocationPopupVisible,
  setLoginPopupVisible,
  setIncompleteOrdersPopupVisible,
  setIncompleteOrdersPopupDismissedByUser,
  setHeaderSearchOpen,
  setLastSearchQuery,
  addPageConfiguration
} = sharedSlice.actions;

export const hideCartSidebar = (dispatch) => {
  dispatch(setCartSidebar(false));
  useBodyScrollBlock().releaseBodyScroll();
}

export const hideNavSidebar = (dispatch) => {
  dispatch(setNavSidebar(false));
  useBodyScrollBlock().releaseBodyScroll();
}
export const hideUserSidebar = (dispatch) => {
  dispatch(setUserSidebar(false));
  useBodyScrollBlock().releaseBodyScroll();
}

export const useBodyScrollBlock = () => {
  const blockBodyScroll = () => {
    document.body.style.position = "fixed";
  };
  const releaseBodyScroll = () => {
    document.body.style.position = null;
  };

  return {blockBodyScroll, releaseBodyScroll}
}

export const usePageConfiguration = (name, initialStub = null) => {
  const pageConfigurations = useSelector(state => state.shared.pageConfigurations)
  const dispatch = useDispatch();
  const [data, setData] = useState(null);
  const [stubLoaded, setStubLoaded] = useState(false);

  useEffect(() => {
    if (!pageConfigurations[name])
      axios.get(`/api/frontend/cms_block_configurations/${name}.json`).then(response => {
        if (response?.data && Object.keys(response.data).length)
          setData(response.data);
      })
  }, [])

  useEffect(() => {
    if (data || (!pageConfigurations[name] && initialStub && !stubLoaded)) dispatch(addPageConfiguration({name, data: data || initialStub}));
    if (!data && !stubLoaded) setStubLoaded(true);
  }, [initialStub, data])

  return useSelector(state => state.shared.pageConfigurations[name])
}

export default sharedSlice.reducer;
