import {createSlice} from '@reduxjs/toolkit';
import axios from 'axios';
import {resolveOnCondition} from '../../utils/injections';
import CatalogLoader from '../helpers/CatalogLoader';
import CartData from '../../react-services/cart-data-rx';
import {resetCheckout, setShipAddress} from './checkout';
import {addPreferenceAddress, loadNearestDeliveryTime} from './session';

const cartSlice = createSlice({
  name: 'cart',
  initialState: {
    minimumOrderValue: -1,
    currentCart: {
      get isSet() { return !!this?.id }
    },
    cartProducts: [],
    cartTaxons: [],
    cartLoading: false,
    checkoutAllowed: false,
    cartValidationError: null
  },
  reducers: {
    currentCartLoaded: (state, action) => {
      state.currentCart = {
        ...action.payload.cart,
        get isSet() { return !!this?.id }
      };
      if (action.payload.products instanceof Array) state.cartProducts = [...action.payload.products];
      if (action.payload.taxons instanceof Array) state.cartTaxons = [...action.payload.taxons];
    },
    setMinimumOrderValue: (state, action) => {
      state.minimumOrderValue = action.payload;
    },
    setCartProducts: (state, action) => {
      state.cartProducts = action.payload.products;
      if (action.payload.taxons) state.cartTaxons = action.payload.taxons;
    },
    setCheckoutAllowed: (state, action) => {
      state.checkoutAllowed = action.payload;
    },
    setCartValidationError: (state, action) => {
      state.cartValidationError = action.payload;
    },
    setCartLoading: (state, action) => {
      state.cartLoading = action.payload;
    }
  }
});

export const loadCurrentCart = (dispatch) => {
  return new Promise((resolve, reject) => {
    resolveOnCondition(() => CartData !== null && CartData.cart !== null && CartData.cart.number !== null).then(() => {
      CartData.load().then(cart => {
        loadCartProducts(dispatch, cart).then(result => {
          dispatch(currentCartLoaded({cart}));
          if (cart?.ship_address) {
            dispatch(setShipAddress(cart.ship_address));
            addPreferenceAddress(dispatch, cart.ship_address, 'ship');
          }
          resolve(cart);
        });
      });
    });
  });
};

export const loadMinimumOrderValue = (dispatch) => {
  axios.get('/api/frontend/orders/expected_minimum_order_value.json').then(response => {
    dispatch(setMinimumOrderValue(response.data.minimum_order_value));
  });
};

export const initCartState = (dispatch) => {
  return resolveOnCondition(() => CartData != null && CartData.cart != null && CartData.cart.number != null, 100).then(() => {
    loadCurrentCart(dispatch);
    loadMinimumOrderValue(dispatch);
    loadNearestDeliveryTime(dispatch);
  });
};

export const loadCartProducts = (dispatch, cart) => {
  const productIds = cart.line_items.map(i => i.product_id);

  return new Promise((resolve, reject) => {
    CatalogLoader.loadProducts({ids: productIds, include_taxons: true, for_cart: true, per_page: 300}).then(result => {
      dispatch(setCartProducts(result));
      resolve(result);
    });
  });
};

export const resetCart = (dispatch) => {
  return new Promise((resolve, reject) => {
    CartData.resetCart().then(() => {
      dispatch(resetCheckout());
      dispatch(currentCartLoaded({cart: JSON.parse(JSON.stringify(CartData.cart))}));
      resolve(CartData.cart);
    });
  });
};

export const {
  currentCartLoaded,
  setMinimumOrderValue,
  setCartProducts,
  setCheckoutAllowed,
  setCartValidationError,
  setCartLoading
} = cartSlice.actions;

export default cartSlice.reducer;
