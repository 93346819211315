import store from "../react-store/store";
import {queueNotification} from "../react-store/reducers/notifications";

const Alerts = (() => {
  const $scope = this || {};

  window.Alerts = this; // window.Alerts is now directed to this service

  // Returns a farmy-notification from the common former Alerts (window and service)
  function enqueueFromPreviousVersion(message, options, type) {
    var notification = {message: message, style: type};

    if (message != null && typeof(message) == 'object') {
      notification = {...message, ...{style: type}};
    } else if (options && typeof(options) == 'object') {
      notification = {...options, ...notification};
    }

    return $scope.enqueue(notification);
  }

  $scope.enqueue = function (notification) {
    return new Promise((resolve, reject) => {
      store.dispatch(queueNotification(notification))
      resolve(notification);
    })
  };

  $scope.error = function(message, options) {
    return enqueueFromPreviousVersion(message, options, 'error')
  };

  $scope.info = function(message, options) {
    return enqueueFromPreviousVersion(message, options, 'info')
  };

  $scope.success = function(message, options) {
    return enqueueFromPreviousVersion(message, options, 'success')
  };

  $scope.warn = function(message, options) {
    return enqueueFromPreviousVersion(message, options, 'warn')
  };

  return $scope;
})();

window.Alerts = Alerts;

export default Alerts;