import axios from "axios";
import * as _ from "underscore";
import TaxonHelper from "../../react-services/taxon-helper-rx";

const CatalogLoader = {
  CATEGORIES_ROOT_TAXON_ID: window.rootCategoriesTaxonId,
  CATEGORIES_ROOT_TAXON: window.rootCategoriesTaxon,

  sessionProvider: null,

  setSessionProvider: (provider) => {
    this.sessionProvider = provider
  },

  loadProducts: (options) => {
    return new Promise((resolve, reject) => {
      let params = {}

      params.template = 'product_in_catalog';
      params.per_page = options.per_page || 24;
      params.page = options.page || 1;

      if (options.keywords) {
        params.keywords = options.keywords;
      }
      if (options.taxon_ids) {
        params.s = params.s || {};
        params.s.taxon_ids = options.taxon_ids.join(",");
      }
      if (options.taxon_id) {
        params.s = params.s || {};
        params.s.taxon_ids = [options.taxon_id.toString()];
      }
      if (options.masterFilters && Object.values(options.masterFilters).filter(i => i).length) {
        params.s = params.s || {};
        let filters = options.masterFilters;
        let filter_keys = Object.keys(filters).filter(k => filters[k]);
        filter_keys.forEach(key => {
          params.s[`master_${key}`] = 'true';
        })
        console.log({params})
      }
      if (options.ids) {
        params.ids = options.ids.join(',');
      }
      if (options.taxon_permalink) {
        params.taxon_permalink = options.taxon_permalink;
      }
      if (options.for_cart) {
        params.include_unaddable = 't';
        params.include_unavailable = 't'
      }
      if (options.include_taxons) params.include_taxons = 't';

      if (options.hub_id) params.hub_id = options.hub_id;

      if (options.filterSelection && Object.keys(options.filterSelection).length) {
        // Strip "page" key from filter selections.
        let filteredFilterSelection = (({page, ...obj}) => obj)(options.filterSelection)

        params.s = {...params.s, ...filteredFilterSelection};
        if (options.hub_id || window.currentHubId)
          params.s.hub_ids = [options.hub_id || window.currentHubId.toString()];
      }

      if (options.sort_mode) params.sort_mode = options.sort_mode;

      if (options.isSavingPrice) params.s = {is_saving_price: 'true', ...params.s};

      var source = axios.CancelToken.source();

      if (options.cancelTokenPlaceholder) {
        options.cancelTokenPlaceholder.source = source;
      }

      axios.get(`/api/products.json`, {params: params, cancelToken: source.token }).then(response => {
        const page = response.data;

        if (options.setter) {
          options.setter(page)
        }

        resolve(page)
      }).catch(e => reject(e));
    })
  },

  loadProduct: (productId, options) => {
    return new Promise((resolve, reject) => {
      const params = {};
      if (options.propsSortingList) params.props_sorting_list = 't';

      axios.get(`/api/products/${productId}.json`, {params}).then(response => {
        const product = response.data;

        if (options.setter) {
          options.setter(product)
        }

        resolve(product)
      }).catch(e => reject(e));
    });
  },

  loadTaxonInfo: (taxonId, options) => {
    let otherOptions = {
      description: true,
      include_direct_children: true,
      seo_url: true,
      alternate_seo_urls: true
    }

    return new Promise((resolve, reject) => {
      TaxonHelper.getTaxonInfo(taxonId, {...options, ...otherOptions}).then((taxonData) => {
        if (options.setter) options.setter(taxonData);
        resolve(taxonData);
      });
    })
  },

  loadPrimaryCategoriesLegacy: () => {
    return new Promise((resolve, reject) => {
      TaxonHelper.getTaxonInfos([window.rootCategoriesTaxonId], {
        include_direct_children: true,
        seo_url: true,
        ignoreCache: true
      }).then(result => {
        const taxonIdsToExclude = [];

        let rootTaxon = _(result).find((t) => t.id == window.rootCategoriesTaxonId);
        let additionalTaxons = _(_(result).reject(t => t.id == rootTaxon.id)).map(t => _(t).pick('id', 'name', 'permalink', 'seo_url', 'depth'));

        // Final result: root.children - excluded + included.
        let menuTaxons = _(rootTaxon.children).reject((t) => taxonIdsToExclude.includes(t.id)).concat(additionalTaxons);

        // Sort by depth:
        menuTaxons = _.sortBy(menuTaxons, t => t.depth);

        // Form menu tree nodes:
        const taxonMenuItems = menuTaxons.map(t => {
          return {text: t.name, customData: {link: t.seo_url, id: t.id}}
        })

        resolve(taxonMenuItems)
      })
    })
  },

  loadPrimaryCategories: () => {
    return new Promise((resolve, reject) => {
      let rootTaxonId = window.rootCategoriesTaxonId;
      let params = {
        id: rootTaxonId,
        locale: I18n.locale,
        include_direct_children: true,
        seo_url: true
      }

      axios.get(`/api/frontend/taxons/taxon_info.json`, {params: params}).then(response => {
        let primaryCategories = response.data?.taxon?.children;
        resolve(primaryCategories)
      })
    })
  },

  loadAllTaxons: () => {
    return new Promise((resolve, reject) => {
      let params = {
        include_counts: true,
        locale: I18n.locale
      }

      axios.get(`/api/frontend/taxons/flatten.json`, {params: params}).then(response => {
        let allTaxons = response.data.taxons;
        resolve(allTaxons)
      })
    })
  }
}

function redirectToNotFound() {
  window.location.pathname = `/404`;
}

export default CatalogLoader
