import {createSlice} from '@reduxjs/toolkit';
import axios from 'axios';
import store from '../store';
import {BlankAddressObject, isValidAddress} from '../helpers/AddressHelper';
import {loadNearestDeliveryTime, setCurrentHub} from './session';

export const checkoutStages = ['loading', 'delivery_mode', 'shipping', 'delivery_time', 'recommend', 'payment', 'complete'];

const checkoutSlice = createSlice({
  name: 'checkout',
  initialState: {
    currentCheckout: null,
    shipAddress: null,
    billAddress: null,
    availableDeliverySlots: [],
    selectedDeliverySlot: null,
    availablePaymentMethods: [],
    selectedPaymentMethod: null,
    payPopupIsOpen: false,
    paybackNumber: "",
    appliedCouponCodes: [],
    checkoutError: null,
    couponError: null,
    paymentError: null,
    deliverySlotError: null,
    addressError: null,
    addressValidated: false,
    undeliverableItems: [],
    deliveryModeHandled: false, // checkout state flag to collapse/fold certain sections
    addressHandled: false, // checkout state flag to collapse/fold certain sections
    deliverySlotHandled: false, // checkout state flag to collapse/fold certain sections
    paymentHandled: false, // checkout state flag to collapse/fold certain sections
    agreedToTermsOfService: false // state flag to determine whether complete purchase button should be active or not
  },
  reducers: {
    resetCheckout: (state, action) => {
      state.currentCheckout = null;
      state.shipAddress = null;
      state.billAddress = null;
      state.availableDeliverySlots = [];
      state.selectedDeliverySlot = null;
      state.availablePaymentMethods = [];
      state.selectedPaymentMethod = null;
      state.payPopupIsOpen = false;
      state.paybackNumber = "";
      state.appliedCouponCodes = [];
      state.checkoutError = null;
      state.couponError = null;
      state.paymentError = null;
      state.deliverySlotError = null;
      state.addressError = null;
      state.addressValidated = false;
      state.undeliverableItems = [];
      state.deliveryModeHandled = false;
      state.addressHandled = false;
      state.deliverySlotHandled = false;
      state.paybackHandled = false;
      state.paymentHandled = false;
      state.agreedToTermsOfService = false;
    },
    setCurrentCheckout: (state, action) => {
      const checkout = action.payload;
      state.currentCheckout = {...checkout, checkout_stage: ensureStageName(checkout?.checkout_stage)};
    },
    setDeliveryMode: (state, action) => {
      state.currentCheckout = {...state.currentCheckout, delivery_mode: action.payload};
    },
    setShipAddress: (state, action) => {
      let shipAddressAttributes = null;
      let shipAddress = null;
      if (action.payload) {
        shipAddress = {...action.payload};
        let {address1, city, company, country_id, firstname, lastname, house_number, phone, title, zipcode} = action.payload;
        shipAddressAttributes = {address1, city, company, country_id, firstname, lastname, house_number, phone, title, zipcode};
      }
      state.shipAddress = shipAddress || BlankAddressObject;
      state.shipAddressAttributes = shipAddressAttributes || BlankAddressObject;
    },
    setSpecialInstructions: (state, action) => {
      state.currentCheckout = {...state.currentCheckout, special_instructions: action.payload};
    },
    setBillAddress: (state, action) => {
      let billAddressAttributes = null;
      let billAddress = null;
      if (action.payload) {
        billAddress = action.payload;
        let {address1, city, company, country_id, firstname, lastname, house_number, phone, title, zipcode} = action.payload;
        billAddressAttributes = {address1, city, company, country_id, firstname, lastname, house_number, phone, title, zipcode};
      }
      state.billAddress = billAddress || BlankAddressObject;
      state.billAddressAttributes = billAddressAttributes || BlankAddressObject;
    },
    setAvailableDeliverySlots: (state, action) => {
      state.availableDeliverySlots = action.payload;
    },
    setDeliverySlot: (state, action) => {
      state.currentCheckout = {...state.currentCheckout, delivery_slot_id: action.payload?.id};
      state.selectedDeliverySlot = action.payload;
    },
    setAvailablePaymentMethods: (state, action) => {
      state.availablePaymentMethods = action.payload;
    },
    setAppliedCouponCodes: (state, action) => {
      state.appliedCouponCodes = [...action.payload];
    },
    setAddressValidated: (state, action) => {
      state.addressValidated = [...action.payload];
    },
    addAppliedCouponCode: (state, action) => {
      state.appliedCouponCodes.push = action.payload;
    },
    setPaymentMethod: (state, action) => {
      const paymentMethod = action.payload.paymentMethod;
      const user = action.payload.user;

      const paymentsAttributes = {
        payment_method_id: paymentMethod.id
      };

      if (paymentMethod.type === 'Spree::PaymentMethod::AccountBalance') {
        paymentsAttributes.source_id = user && user.account && user.account.id; // TODO: Fill this from the profile data
        paymentsAttributes.source_type = 'Spree::UserAccount';
      }

      state.currentCheckout = {
        ...state.currentCheckout,
        payment_method_id: paymentMethod.id,
        payments_attributes: paymentsAttributes
      };

      window.localStorage.setItem('paymentMethodName', paymentMethod.locale_name || null);

      state.selectedPaymentMethod = paymentMethod;
    },
    setPayPopupIsOpen: (state, action) => {state.payPopupIsOpen = action.payload},
    setPaybackNumber: (state, action) => { state.paybackNumber = action.payload },
    setDeliveryModeHandled: (state, action) => { state.deliveryModeHandled = action.payload },
    setAddressHandled: (state, action) => { state.addressHandled = action.payload },
    setDeliverySlotHandled: (state, action) => { state.deliverySlotHandled = action.payload },
    setPaymentHandled: (state, action) => { state.paymentHandled = action.payload },
    setPaybackHandled: (state, action) => { state.paybackHandled = action.payload },
    setAgreedToTermsOfService: (state, action) => { state.agreedToTermsOfService = action.payload },
    setCheckoutError: (state, action) => {
      console.error('Checkout error:', {...action.payload});
      if (action.payload.checkout !== null) state.checkoutError = action.payload.checkout;
      if (action.payload.payment !== null) state.paymentError = action.payload.payment;
      if (action.payload.coupon !== null) state.couponError = action.payload.coupon;
      if (action.payload.address !== null) state.addressError = action.payload.address;
      if (action.payload.deliverySlot !== null) state.deliverySlotError = action.payload.deliverySlot;
    },
    resetDeliverySlot: (state, action) => {
      state.selectedDeliverySlot = null;
      state.availableDeliverySlots = null;
    },
    resetSelectedPaymentMethod: (state, action) => {
      state.selectedPaymentMethod = null;
    }
  }
});

export const ensureStageName = (name) => {
  return checkoutStages.includes(name) ? name : null;
};

export const loadCurrentCheckout = (dispatch, number, token) => {
  const requestStr = `/api/checkouts/${number}?order_token=${token}&locale=${I18n.locale}`;

  return new Promise((resolve, reject) => {
    axios.get(requestStr).then(response => {
      dispatch(setCurrentCheckout(response.data));
      // Restore some other state flags that control which checkout state is visible:
      if (response.data.delivery_mode) {
        dispatch(setDeliveryModeHandled(true));
      } else {
        dispatch(setDeliveryModeHandled(false));
      }

      dispatch(setBillAddress(response.data.bill_address));
      dispatch(setShipAddress(response.data.ship_address));
      if (isValidAddress(response.data.ship_address, response.data) && isValidAddress(response.data.bill_address)) {
        dispatch(setAddressHandled(true));
      }

      dispatch(setDeliverySlot(response.data.delivery_slot));
      dispatch(setPaybackNumber(response.data.payback_number || ''));

      if (store.getState().checkout.selectedPaymentMethod) {
        dispatch(setPaymentMethod({paymentMethod: store.getState().checkout.selectedPaymentMethod}));
        dispatch(setPaymentHandled(true));
      }

      // Force pristine checkout state when the checkout_stage is at initial delivery_mode,
      // this enabled easy visual checkout 'reset', to make the user go through all the steps again
      if (!response?.data?.checkout_stage || response.data.checkout_stage === 'delivery_mode') {
        dispatch(setDeliveryModeHandled(false));
        dispatch(setAddressHandled(false));
        dispatch(setDeliverySlotHandled(false));
      }

      resolve(response.data);
    }, e => {
      if (window.Alerts) window.Alerts.error(errorMessage(e));
      reject(e);
    });
  });
};

export const validateCheckoutAddress = (dispatch, checkout, address) => {
  const params = {address, order_token: checkout.token};

  return new Promise((resolve, reject) => {
    axios.post(`/api/checkouts/${checkout.number}/validate_address.json`, params).then(response => {
      resolve(response.data);
    }).catch(e => {
      console.error(e);
      reject(e);
    });
  });
};

export const switchCheckoutHubs = (dispatch, checkout, newHub) => {
  return new Promise((resolve, reject) => {
    axios.post(`/api/checkouts/${checkout.number}/switch_hubs.json`, {hub_id: newHub.id, order_token: checkout.token})
      .then(response => {
        const newHub = response.data?.new_hub;

        if (newHub) {
          dispatch(setCurrentHub(newHub));
          loadNearestDeliveryTime(dispatch, newHub.id);
        }

        resolve(response.data);
      }).catch(e => {
        console.error(e);
        reject(e);
      });
    });
};

export const loadDeliverySlots = (dispatch, checkout) => {
  return new Promise((resolve, reject) => {
    const params = {
      no_supplier_restrictions: 't',
      no_special_date_restrictions: 't',
      locale: I18n.locale,
      order_token: checkout.token
    };

    axios.get(`/api/checkouts/${checkout.number}/delivery_slots.json`, {params}).then(response => {
      if (!response.data.not_available_delivery_slot) {
        dispatch(setAvailableDeliverySlots(response.data.delivery_slots));
        resolve(response.data.delivery_slots);
      } else {
        reject('Dieser Dienst ist für diese Adresse nicht verfügbar');
      }
    });
  });
};

export const loadPaymentMethods = (dispatch, checkout) => {
  return new Promise((resolve, reject) => {
    const params = {
      locale: I18n.locale,
      order_token: checkout.token,
    };

    axios.get(`/api/checkouts/${checkout.number}/available_payment_methods.json`, {params}).then(response => {
      dispatch(setAvailablePaymentMethods(response.data.payment_methods));
      resolve(response.data.payment_methods);
    });
  });
};

export const updateDeliveryMode = (dispatch, checkout, deliveryMode, currentHub) => {
  const params = {
    order: {
      delivery_mode: deliveryMode
    }
  };

  if (deliveryMode === 'pickup') {
    params.order.hub_id = currentHub?.id;
  }

  return axios.put(`/api/checkouts/${checkout.number}?order_token=${checkout.token}&locale=${I18n.locale}&no_state_transition=t`, params).then(response => {
    dispatch(setCurrentCheckout(response.data));
  });
};

export const updateShippingAddress = (dispatch, checkout, address, useForBilling) => {
  const params = {
    order: {
      ship_address_attributes: {
        title: address.title,
        country_id: window.defaultCountryId,
        firstname: address.firstname,
        lastname: address.lastname,
        company: address.company,
        address1: address.address1,
        house_number: address.house_number,
        zipcode: address.zipcode,
        city: address.city,
        phone: address.phone,
        is_hub: address.is_hub
      },
      use_for_billing: useForBilling
    }
  };

  try {
    ahoy.track('new-customer-address', {
      order_id: checkout?.id,
      order_number: checkout?.number,
      new_shipping_address_attributes: params.order.ship_address_attributes,
      old_shipping_address_attributes: {
        title: checkout?.ship_address?.title,
        country_id: checkout?.ship_address?.country_id,
        firstname: checkout?.ship_address?.firstname,
        lastname: checkout?.ship_address?.lastname,
        company: checkout?.ship_address?.company,
        address1: checkout?.ship_address?.address1,
        house_number: checkout?.ship_address?.house_number,
        zipcode: checkout?.ship_address?.zipcode,
        city: checkout?.ship_address?.city,
        phone: checkout?.ship_address?.phone
      },
      channel: checkout?.subchannel
    });
  } catch (error) {
    console.error(error);
  }

  return axios.put(`/api/checkouts/${checkout.number}?order_token=${checkout.token}&locale=${I18n.locale}&no_state_transition=t&no_line_items=t`, params).then(response => {
    dispatch(setCurrentCheckout(response.data));
    dispatch(setShipAddress(response.data.ship_address));
  }, error => {
    dispatch(setCheckoutError({address: error.response.data}));
  });
};

export const updateSpecialInstructions = (dispatch, checkout, specialInstructions) => {
  return updateCheckout(dispatch, { id: checkout.id, number: checkout.number, token: checkout.token, special_instructions: specialInstructions }, false);
};

export const updateBillingAddress = (dispatch, checkout, address) => {
  const params = {
    order: {
      bill_address_attributes: {
        title: address.title,
        country_id: window.defaultCountryId,
        firstname: address.firstname,
        lastname: address.lastname,
        company: address.company,
        address1: address.address1,
        house_number: address.house_number,
        zipcode: address.zipcode,
        city: address.city,
        phone: address.phone
      }
    }
  };

  return axios.put(`/api/checkouts/${checkout.number}?order_token=${checkout.token}&locale=${I18n.locale}&no_state_transition=t&no_line_items=t`, params).then(response => {
    dispatch(setCurrentCheckout({...response.data, line_items: checkout.line_items}));
  });
};

export const updateDeliverySlot = (dispatch, checkout, deliverySlot) => {
  return new Promise((resolve, reject) => {
    const params = {
      id: checkout.number,
      order_token: checkout.token,
      order: {delivery_slot_id: deliverySlot.id},
      delivery_slot_id: deliverySlot.id
    };

    axios.put(`/api/checkouts/${checkout.number}/update_delivery_slot.json?no_min_order_value=t&no_shipments=t&no_payments=t&no_state_specific=t`, params)
      .then((response) => {
        if (response.data?.delivery_slot) dispatch(setDeliverySlot(response.data.delivery_slot));
        resolve(response.data);
      }, (error) => {
        dispatch(setCheckoutError({deliverySlot: error.response.data}));
        reject(error);
      })
  });
};

/**
 * Tries to apply a coupon code to an existing checkout.
 *
 * @param dispatch
 * @param checkout
 * @param couponCode
 */
export const applyCouponCode = (dispatch, checkout, couponCode) => {
  return new Promise((resolve, reject) => {
    const params = {
      id: checkout.number,
      order_token: checkout.token,
      code: couponCode
    };

    axios.put(`/api/checkouts/${checkout.number}/apply_coupon_code.json?no_shipments=t&no_state_specific=t`, params)
      .then((response) => {
        if (window && window.Alerts) window.Alerts.success('Gutscheincode wurde zu Ihrer Bestellung hinzugefügt!');
        // dispatch(setCurrentCheckout(response.data))
        resolve(response.data);
      }, (error) => {
        dispatch(setCheckoutError({coupon: error.response.data}));
        reject(error);
      });
  });
};

export const sendPaymentStartSignal = (orderNumber) => {
  return axios.put(`/api/checkouts/${orderNumber}/payment_started.json`);
}

export const sendPaymentCancelSignal = (orderNumber) => {
  return axios.put(`/api/checkouts/${orderNumber}/payment_cancelled.json`);
}

/**
 * Advances the checkout state (i.e. order.state) till the expectedState is reached.
 * Doesn't modify anything in the order besides its state.
 *
 * @param dispatch
 * @param checkout
 * @param expectedState
 * @returns {Promise<AxiosResponse<any>>}
 */
export const advanceCheckoutState = (dispatch, checkout, expectedState) => {
  const params = {order: {id: checkout.id}};

  return axios.put(`/api/checkouts/${checkout.number}/next_until.json?order_token=${checkout.token}&locale=${I18n.locale}&expected_state=${expectedState}`, params).then(response => {
    dispatch(setCurrentCheckout(response.data));
    if (response.data.ship_address) {
      dispatch(setShipAddress(response.data.ship_address));
    }
  });
};

/**
 * Makes a complete checkout update
 *
 * @param dispatch
 * @param checkout The checkout object that will be used for params
 * @param advance If order.next should be called upon a successful update
 * @returns {Promise<void>}
 */
export const updateCheckout = (dispatch, checkout, advance) => {
  const params = {order: {...checkout}};

  return axios.put(`/api/checkouts/${checkout.number}?order_token=${checkout.token}&locale=${I18n.locale}${advance ? '' : '&no_state_transition=t'}`, params).then(response => {
    dispatch(setCurrentCheckout({...response.data}));
    if (response.data.ship_address) {
      dispatch(setShipAddress(response.data.ship_address))
    }
  }, error => {
    dispatch(setCheckoutError({checkout: error.response.data}));
  });
};

/**
 * Makes a complete checkout update
 *
 * @param dispatch
 * @param checkout The checkout object that will be used for params
 * @param advance If order.next should be called upon a successful update
 * @returns {Promise<void>}
 */
export const updateCheckoutStage = (dispatch, checkout, stage) => {
  const params = {stage};

  return axios.post(`/api/checkouts/${checkout.number}/update_stage?order_token=${checkout.token}&locale=${I18n.locale}`, params).then(response => {
    const newStage = response.data.checkout_stage;
    dispatch(setCurrentCheckout({...store.getState().checkout.currentCheckout, checkout_stage: newStage}));
  }, error => {
    dispatch(setCheckoutError({checkout: error.response.data}));
  });
};

export const submitCheckout = (dispatch, checkout) => {
  const params = {order: {...checkout}};

  return axios.put(`/api/checkouts/${checkout.number}?order_token=${checkout.token}&locale=${I18n.locale}&advance_to_completion=t&no_line_items=t`, params).then(response => {
    dispatch(setCurrentCheckout(response.data));
  }, error => {
    dispatch(setCheckoutError({checkout: error.response.data}));
  });
};

export const {
  resetCheckout,
  setCurrentCheckout,
  setDeliveryMode,
  setDeliverySlot,
  setShipAddress,
  setBillAddress,
  setSpecialInstructions,
  setDeliveryModeHandled,
  setAddressHandled,
  setDeliverySlotHandled,
  setPaymentHandled,
  setAvailableDeliverySlots,
  setPaymentMethod,
  setPayPopupIsOpen,
  setAvailablePaymentMethods,
  setAppliedCouponCodes,
  addAppliedCouponCode,
  setPaybackHandled,
  setPaybackNumber,
  setCheckoutError,
  setAddressValidated,
  setAgreedToTermsOfService,
  resetDeliverySlot,
  resetSelectedPaymentMethod
} = checkoutSlice.actions;

export default checkoutSlice.reducer;
