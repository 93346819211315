import {createSlice} from '@reduxjs/toolkit';
import CatalogLoader from "../helpers/CatalogLoader";

const catalogSlice = createSlice({
  name: 'catalog',
  initialState: {
    currentFilters: [],
    currentFilterSelection: {},
    masterFilters: null,
    primaryCategories: null,
    allTaxons: null,
    currentTaxon: null,
    currentProduct: null,
    currentSortMode: 'editorial_best',
    isLoading: false,
    isSupplierView: false
  },
  reducers: {
    setCurrentFilters: (state, action) => {
      state.currentFilters = action.payload.filters;
    },
    setMasterFilters: (state, action) => {
      state.masterFilters = action.payload;
    },
    setCurrenFilterSelection: (state, action) => {
      state.currentFilterSelection = action.payload.filterSelection;
    },
    setCurrentSortMode: (state, action) => {
      state.currentSortMode = action.payload.sortMode;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload.isLoading;
    },
    setPrimaryCategories: (state, action) => {
      state.primaryCategories = action.payload.taxons;
    },
    setAllTaxons: (state, action) => {
      state.allTaxons = action.payload.taxons;
    },
    setCurrentTaxon: (state, action) => {
      state.currentTaxon = action.payload;
    },
    setCurrentProduct: (state, action) => {
      state.currentProduct = action.payload;
    }
  }
});

const fetchPrimaryCategories = (dispatch) => {
  return new Promise((resolve, reject) => {
    CatalogLoader.loadPrimaryCategories().then(taxons => {
      dispatch(setPrimaryCategories({taxons: taxons}));
      resolve(taxons)
    })
  })
}

const fetchAllTaxons = (dispatch) => {
  return new Promise((resolve, reject) => {
    CatalogLoader.loadAllTaxons().then(taxons => {
      dispatch(setAllTaxons({taxons: taxons}));
      resolve(taxons)
    })
  })
}

export const getDirectChildren = (taxon, allTaxons) => {
  return allTaxons?.filter(t => t.parent_id === taxon.id)
}

export const fetchTaxons = (dispatch) => {
  fetchPrimaryCategories(dispatch);
  fetchAllTaxons(dispatch);
}

/**
 * Returns a selection object from url search parameters:
 * @example url-search "?s%5Bcertificate_ids%5D%5B%5D=15&s%5Bcertificate_ids%5D%5B%5D=7&s%5Bsupplier_id%5D%5B%5D=62"
 * @example result => {"certificate_ids": ["15", "7"], "supplier_id": ["62"]}
 */
export const extractUrlParams = (preservePageNumber = true) => {
  let params = {};
  const searchParams = new URLSearchParams(decodeURIComponent(location.search));

  let keys = Array.from(new Set(searchParams.keys()));
  const sParamsReg = new RegExp(/s\[(.*)\]\[\]/);

  if (!preservePageNumber)
    keys = keys.filter(k => k !== 'page');

  // Regular keys:
  keys.filter(k => !sParamsReg.test(k))
    .forEach(key => {
      let values = searchParams.get(key)
      params[key] = values
    })

  // 's' keys:
  keys.forEach(rawKey => {
    const key = sParamsReg.test(rawKey) ? rawKey.match(reg)[1] : null;

    if (key)
      params[key] = searchParams.getAll(rawKey)
  })

  return params
}

/**
 * Same as "extractUrlParams", plus splits parameters into "selections" and "sort_mode".
 * @returns {sort_mode, selection}
 */
export const extractSplitUrlParams = () => {
  let params = extractUrlParams();

  let {sort_mode, q, ...selection} = params;

  return {sort_mode, q, selection}
}

export const parseSelectionFromUrl = () => {
  let selection = extractSplitUrlParams().selection
  if (selection) {
    Object.keys(selection).forEach((key) => selection[key] = selection[key].split(','))
  }

  return selection || {}
}

export const parseSortModeFromUrl = () => {
  return extractSplitUrlParams().sort_mode
}

export const parseSearchQueryFromUrl = () => {
  return extractSplitUrlParams().q
}

export const getFilterSelection = () => {
  let params = parseSelectionFromUrl();

  return params;
}

export const updateFilterSelection = (newSelection = {}, dispatch, updateUrl = false, history) => {
  // if (!newSelection || !Object.keys(newSelection).length)
  //   return

  let newPath;
  const sortMode = parseSortModeFromUrl();
  const searchQuery = parseSearchQueryFromUrl();
  const currentSelection = getFilterSelection() || {};

  let resultingSelection = {...currentSelection};
  const key = Object.keys(newSelection)[0];

  if (key) {
    const value = newSelection[key].toString();

    if (key === 'taxon_ids' || newSelection.permalink) {
      newPath = newSelection.permalink;
      delete newSelection.permalink;
    } else {
      resultingSelection[key] = resultingSelection[key] || [];

      if (resultingSelection[key].includes(value)) {
        resultingSelection[key] = resultingSelection[key].filter(i => i !== value)
        if (!resultingSelection[key].length) delete resultingSelection[key];
      }  else {
        resultingSelection[key].push(value)
      }
    }

  }

  let params = {...resultingSelection};
  if(sortMode) params = {...params, ...{sort_mode: sortMode}}
  if(searchQuery) params = {...params, ...{q: searchQuery}}
  
  if (updateUrl) {
    let newUrl = paramsToPath(params, newPath, false)
    history.push(newUrl)
  }

  dispatch(setCurrentSortMode({sortMode}));
  dispatch(setCurrenFilterSelection({filterSelection: resultingSelection}));
}

export const updateCategory = (newCategory, dispatch, history) => {
  if (!newCategory) return;

  let urlParams = extractUrlParams(false);
  let newUrl = paramsToPath(urlParams, newCategory.seo_url, false);

  history.push(newUrl)
}

export const updateSortMode = (newSortMode, dispatch, history) => {
  if(!newSortMode) return;

  let urlParams = extractUrlParams();
  urlParams.sort_mode = newSortMode;

  let newUrl = paramsToPath(urlParams);

  history.push(newUrl);
  dispatch(setCurrentSortMode({sortMode: newSortMode}))
}

export const paramsToPath = (params, path, preservePageNumber = true) => {
  let newPath = path || location.pathname;
  let searchKeys = Object.keys(params).filter(i => i !== 'permalink');

  if (!preservePageNumber)
    searchKeys = searchKeys.filter(k => k !== 'page');

  const newSearch = searchKeys.map(key => `${key}=${params[key]}`);

  if (newSearch?.length)
    newPath += `?${newSearch.join('&')}`;

  return newPath
}

export const paramsToSearch = (params) => {
  let searchKeys = Object.keys(params);
  const newSearch = searchKeys.map(key => `${key}=${params[key]}`);

  return newSearch?.length ? `?${newSearch.join('&')}` : ''
}

export const stripeKeyFromUrlParams = (keyToRemove) => {
  let params = extractUrlParams();
  let searchKeys = Object.keys(params).filter(i => i !== 'permalink');

  if (!keyToRemove)
    return paramsToSearch(params);

  delete params[keyToRemove]

  return paramsToSearch(params)
}

export const initFilters = (dispatch) => {
  return new Promise((resolve, reject) => {
    let filterSelection = getFilterSelection();

  })
}

export const initFilterSelection = (dispatch) => {
  let filterSelection = getFilterSelection();
  dispatch(setCurrenFilterSelection({filterSelection}))

  let sortMode = parseSortModeFromUrl();
  if (sortMode)
    dispatch(setCurrentSortMode({sortMode}))
}

export const {
  setCurrentFilters,
  setMasterFilters,
  setCurrenFilterSelection,
  setIsLoading,
  setCurrentSortMode,
  setPrimaryCategories,
  setAllTaxons,
  setCurrentTaxon,
  setCurrentProduct
} = catalogSlice.actions;

export default catalogSlice.reducer;
